import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import {
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Paper,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Autocomplete,
  TextField,
  DialogActions,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Pagination,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Axios from "axios";
import Sidebar from "../../sidebar/sidebar";
import { companylogo } from "../../../assets/images";
import { BaseUrl } from "../../../Environment";
import { Undo } from "@material-ui/icons";
import moment from "moment";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const loanTableHeader = {
  loanId: "LoanId",
  firstName: "Borrower",
  loanAmount: "Lent",
  totalCollectionAmount: "Collected",
  totalPenaltyApplied: "Penalty",
  balanceAmount: "Balance",
  pendingDues: "Remaining Dues",
};

const collectionTableHeader = {
  SrNo: "Sr. No.",
  DueDate: "Due Date",
  CollectedDate: "Collected Date",
  OpeningBalance: "Opening Balance",
  Installemt: "Installemt",
  Collected: "Collected",
  ClosingBalance: "Closing Balance",
  Penalty: "Penalty",
};

const CustomerWiseReport = () => {
  let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
  let companyCountry = JSON.parse(localStorage.getItem("companyCountry"));

  const radioData = [
    { value: "1", label: "Loans beyond the Total Loan Tenure" },
    { value: "2", label: "Show All" },
  ];

  const [reportTableData, setReportTableData] = useState([]);
  const [loanClnData, setLoanClnData] = useState([]);
  const [showFirstModal, setShowFirstModal] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [loanStatus, setLoanStatus] = useState("Open");
  const [loanPaymentStatus, setLoanPaymentStatus] = useState("");
  const [loanTenure, setLoanTenure] = useState("");
  const [blockDatas, setBlockDatas] = useState({});
  const [showShare, setShowShare] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [number, setNumber] = useState("");
  const [daysWeeksMonths, setDaysWeeksMonths] = useState("days");
  const [greaterLesser, setGreaterLesser] = useState("lesser");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [gotFullData, setGotFullData] = useState(false);
  const [fullReport, setFullReport] = useState(false);
  const [selectedBorrower, setSelectedBorrower] = useState("");
  const [companyName, setCompanyName] = useState(
    loggedinUser?.firstName + " " + loggedinUser?.lastName
  );
  const [currency, setCurrency] = useState(companyCountry?.symbol);
  const [userType, setUserType] = useState(loggedinUser?.userType);

  const Customerlist = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const companyId = localStorage.getItem("loggedinUserCompany");
    let collectorId = loggedinUser?.userId;

    const headers = {
      Authorization: "Bearer " + token,
    };

    const payload = {
      companyId,
      collectorId,
      userType,
      fullData: true,
    };

    Axios.post(BaseUrl + "/customer/getAllCustomersMobile", payload, {
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 200) {
          const cList = resp.data?.result?.map((item) => ({
            label: item?.cust_id ? `${item.firstName} ${item.lastName} (${item.cust_id})` : `${item.firstName} ${item.lastName}`,
            value: item.customerId,
          }));
          setCustomerList(cList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function processLoanData(data) {
    const result = {};

    data.forEach((entry) => {
      const loanIDs = entry.loanId;
      const customer = entry.customer;
      const collectorId = entry.collectorId;
      const loanCollections = entry?.loanCollections?.filter(
        (data) => data?.collectionStatus != null
      );

      if (!result[loanIDs]) {
        result[loanIDs] = {
          loanId: loanIDs,
          customer,
          collectorId,
          loanTenureType: entry?.loanTenureType,
          loanTenureVal: entry?.loanTenure,
          loanAmount: entry?.totalAmountToCollect,
          loanStatus: entry?.loanStatus,
          loanPaymentStatus: entry?.loanPaymentStatus,
          collectedDate: entry?.collectedDate,
          createdAt: entry?.createdAt,
          startDate: entry?.startDate,
          totalCollectionAmount: 0,
          totalPenaltyApplied: 0,
          pendingDues: 0,
          balanceAmount: 0,
          loanCollections,
        };
      }

      if (loanCollections) {
        result[loanIDs].pendingDues =
          entry?.loanTenure - loanCollections.length;
        result[loanIDs].totalCollectionAmount = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.collectionAmount);
          },
          0
        );
        result[loanIDs].totalPenaltyApplied = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.PenaltyApplied ?? 0);
          },
          0
        );
        result[loanIDs].balanceAmount =
          loanCollections?.slice(-1)[0]?.closingBalance ?? 0;
      }
    });

    return Object.values(result);
  }

  function processBlockData(data) {
    const result = {};

    data.forEach((entry) => {
      const loanIDs = entry.loanId;
      const loanCollections = entry?.loanCollections?.filter(
        (data) => data?.collectionStatus != null
      );

      if (!result[loanIDs]) {
        result[loanIDs] = {
          loanId: loanIDs,
          loanAmount: entry?.totalAmountToCollect,
          totalCollectionAmount: 0,
          totalPenaltyApplied: 0,
          balanceAmount: 0,
        };
      }

      if (loanCollections) {
        result[loanIDs].pendingDues =
          entry?.loanTenure - loanCollections.length;
        result[loanIDs].totalCollectionAmount = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.collectionAmount);
          },
          0
        );
        result[loanIDs].totalPenaltyApplied = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.PenaltyApplied ?? 0);
          },
          0
        );
        result[loanIDs].balanceAmount =
          loanCollections?.slice(-1)[0]?.closingBalance ?? 0;
      }
    });

    return Object.values(result);
  }

  const getLoansData = async () => {
    try {
      setLoading(true);

      const token = JSON.parse(localStorage.getItem("token"));
      const companyId = localStorage.getItem("loggedinUserCompany");
      let collectorId = loggedinUser?.userId;

      const headers = {
        Authorization: "Bearer " + token,
      };

      const payLoad = {
        companyId,
        collectorId,
        loanPaymentStatus,
        loanStatus,
        loanTenureType: loanTenure,
        limit: 20,
        page,
        fullReport,
        userType,
        customerId,
      };

      const res = await Axios.post(
        `${BaseUrl}/loan/getLoanwiseReport`,
        payLoad,
        {
          headers,
        }
      );

      const { data: rawData } = res;

      const loanData = processLoanData(rawData?.result);
      const blockData = processBlockData(rawData?.totalData);

      setReportTableData(loanData);
      setBlockDataFn(blockData);
      setTotalPages(Math.ceil(parseFloat(res?.data?.totalRecords) / 20));
      if (fullReport) {
        setGotFullData(true);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    Customerlist();
  }, []);

  useEffect(() => {
    if (customerId) {
      getLoansData();
    }
  }, [
    showFirstModal,
    page,
    loanStatus,
    loanPaymentStatus,
    loanTenure,
    fullReport,
  ]);

  useEffect(() => {
    if (gotFullData) {
      // generatePDF();
    }
  }, [gotFullData]);

  const setBlockDataFn = (dataArr) => {
    const sumLoanAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.loanAmount),
      0
    );
    const sumCollectionAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.totalCollectionAmount),
      0
    );
    const sumPenaltyAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.totalPenaltyApplied),
      0
    );
    const sumBalanceAmt = dataArr?.reduce(
      (acc, value) => acc + parseFloat(value?.balanceAmount),
      0
    );

    setBlockDatas({
      sumLoanAmt,
      sumCollectionAmt,
      sumPenaltyAmt,
      sumBalanceAmt,
    });
  };

  const fourBlockData = [
    {
      value: blockDatas?.sumLoanAmt ?? 0,
      heading: "Total Loan Amt",
      color: "#faebfe",
      currency: currency,
    },
    {
      value: blockDatas?.sumCollectionAmt ?? 0,
      heading: "Total Collected Amt",
      color: "#fbedea",
      currency: currency,
    },
    {
      value: blockDatas?.sumPenaltyAmt ?? 0,
      heading: "Total Penalty Amt",
      color: "#f1f0f5",
      currency: currency,
    },
    {
      value: blockDatas?.sumBalanceAmt ?? 0,
      heading: "Total Balance Amt",
      color: "#ebf1fc",
    },
  ];

  const LoanStatusFilter = [
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "All", value: "All" },
  ];

  const PaymentStatusFilter = [
    { label: "Due", value: "Due" },
    { label: "OverDue", value: "OverDue" },
    { label: "All", value: "All" },
  ];

  const SequenceFilter = [
    { value: "onetime", label: "Onetime" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "All", label: "All" },
  ];

  const handleChangeLoanStatus = (event, val) => {
    setPage(1);
    setLoanStatus(val);
  };

  const handleChangePaymentStatus = (event, val) => {
    setPage(1);
    setLoanPaymentStatus(val);
  };

  const handleChangeSequence = (event, val) => {
    setPage(1);
    setLoanTenure(val);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Popper Hooks and Functions
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    setNumber("");
    setRadioValue("");
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  // Table sorting hooks and functions
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const sortedRows = [].slice().sort((a, b) => {
    const sortOrderMultiplier = sortOrder === "asc" ? 1 : -1;
    if (a[sortBy] < b[sortBy]) return -1 * sortOrderMultiplier;
    if (a[sortBy] > b[sortBy]) return 1 * sortOrderMultiplier;
    return 0;
  });

  return (
    <>
      <Sidebar />
      <div style={{ marginLeft: "73px", height: "100vh" }}>
        <Grid container direction={"column"}>
          <Grid item sx={{ backgroundColor: "#fff" }}>
            <div style={{ margin: 5 }}>
              <img src={companylogo} width={200} height={40} alt="logo" />
            </div>
          </Grid>
          <Grid item container paddingX={4} paddingY={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
              <ToggleButtonGroup
                color="primary"
                value={loanStatus}
                exclusive
                onChange={handleChangeLoanStatus}
                aria-label="Platform"
                size="small"
              >
                {LoanStatusFilter?.map((filter, i) => (
                  <ToggleButton key={i} value={filter?.value}>
                    {filter?.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
              <ToggleButtonGroup
                color="primary"
                value={loanPaymentStatus}
                exclusive
                onChange={handleChangePaymentStatus}
                aria-label="Platform"
                size="small"
              >
                {PaymentStatusFilter?.map((filter, i) => (
                  <ToggleButton key={i} value={filter?.value}>
                    {filter?.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
              <ToggleButtonGroup
                color="primary"
                value={loanTenure}
                exclusive
                onChange={handleChangeSequence}
                aria-label="Platform"
                size="small"
              >
                {SequenceFilter?.map((filter, i) => (
                  <ToggleButton key={i} value={filter?.value}>
                    {filter?.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item container paddingX={4} paddingY={2}>
            {fourBlockData?.map((data, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                  <Card
                    elevation={3}
                    sx={{
                      marginRight: 5,
                      minHeight: 130,
                      borderRadius: 3,
                      backgroundColor: "#fff",
                    }}
                  >
                    <CardHeader
                      title={
                        <div style={{ fontSize: 15 }}>{data?.heading}</div>
                      }
                    />
                    <CardContent>
                      <CardHeader
                        title={
                          <div style={{ fontSize: 20 }}>
                            {(data.value ? data.value : "0") +
                              (data.currency ? " " + data.currency : "")}
                          </div>
                        }
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {loanId ? null : (
            <Box paddingX={4} paddingY={2}>
              <p className="Fonts">NOTE: Click on record to see details</p>
            </Box>
          )}

          {loanId ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              paddingX={4}
              paddingY={2}
            >
              <IconButton
                onClick={() => {
                  setLoanId("");
                  setBlockDataFn(reportTableData);
                }}
                size="medium"
              >
                <Undo htmlColor="#000" />
              </IconButton>
              <Typography>
                {selectedBorrower},{loanId}
              </Typography>
            </Stack>
          ) : null}

          <Grid item container paddingX={4} paddingY={2}>
            {loanId ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {Object.keys(collectionTableHeader).map((property) => (
                        <StyledTableCell key={property}>
                          <TableSortLabel
                            active={sortBy === property}
                            direction={sortBy === property ? sortOrder : "asc"}
                            onClick={() => handleSort(property)}
                          >
                            {collectionTableHeader[property]}
                          </TableSortLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanClnData.map((data, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>
                          {moment(data?.dueDate).format("DD/MM/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment(data?.collectedDate).format("DD/MM/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data?.openingBalance}
                        </StyledTableCell>
                        <StyledTableCell>{data?.Installment}</StyledTableCell>
                        <StyledTableCell>
                          {data?.collectionAmount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data?.closingBalance}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data?.PenaltyApplied}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {Object.keys(loanTableHeader).map((property) => (
                          <StyledTableCell key={property}>
                            <TableSortLabel
                              active={sortBy === property}
                              direction={
                                sortBy === property ? sortOrder : "asc"
                              }
                              onClick={() => handleSort(property)}
                            >
                              {loanTableHeader[property]}
                            </TableSortLabel>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportTableData.map((data, index) => (
                        <StyledTableRow
                          key={index}
                          onClick={() => {
                            setLoanId(data?.loanId);
                            setSelectedBorrower(
                              data?.customer?.firstName +
                                " " +
                                data?.customer?.lastName
                            );
                            setBlockDataFn([
                              {
                                loanAmount: data?.loanAmount,
                                totalCollectionAmount:
                                  data?.totalCollectionAmount,
                                totalPenaltyApplied: data?.totalPenaltyApplied,
                                balanceAmount: data?.balanceAmount,
                              },
                            ]);
                            setLoanClnData(data?.loanCollections);
                          }}
                        >
                          <StyledTableCell>{data?.loanId}</StyledTableCell>
                          <StyledTableCell>
                            {
                              <Stack>
                                <div>
                                  {data?.customer?.firstName +
                                    " " +
                                    data?.customer?.lastName}
                                </div>
                                {data?.customer?.cust_id ? <div>({data?.customer?.cust_id})</div> : null}
                              </Stack>
                            }
                          </StyledTableCell>
                          <StyledTableCell>{data?.loanAmount}</StyledTableCell>
                          <StyledTableCell>
                            {data?.totalCollectionAmount}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data?.totalPenaltyApplied}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data?.balanceAmount}
                          </StyledTableCell>
                          <StyledTableCell>{data?.pendingDues}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  size="large"
                  style={{ marginTop: 10 }}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={showFirstModal}
          onClose={() => setShowFirstModal(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Customer-Wise Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Choose the Customer from here.
            </DialogContentText>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerList}
              sx={{ marginTop: 5, width: 350, height: 100 }}
              onChange={(event, newValue) => {
                setCustomerId(newValue?.value);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Customer" />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setShowFirstModal(false)}
            >
              Show Report
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default withNamespaces()(CustomerWiseReport);
