import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCTi7wf_rilt9LsALoJODqC6vikKfLFC6Q",
  authDomain: "lendstack-dc456.firebaseapp.com",
  projectId: "lendstack-dc456",
  storageBucket: "lendstack-dc456.appspot.com",
  messagingSenderId: "1024707735976",
  appId: "1:1024707735976:web:6b1268e927ba87dd9a2d9d",
  measurementId: "G-XHY0WMWX5F",
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
var provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };
