import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Paper,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  InputBase,
} from "@mui/material";
import { DeleteSharp, EditSharp, Search } from "@mui/icons-material";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import {
  getAllBankAccountsDataAPI,
  addBankAccountAPI,
  updateBankAccountAPI,
  deleteBankAccountAPI,
} from "../../service/service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./BankAccountManagement.scss";

const BankAccountManagement = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [payload, setPayload] = useState({
    code: "",
    bankName: "",
  });

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const [errors, setErrors] = useState({
    code: "",
    bankName: "",
  });

  const [accountId, setAccountId] = useState(null);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [deleteBankAccountModal, setDeleteBankAccountModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const fetchData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const data = {
        companyId,
        searchQuery: debouncedQuery,
      };

      const resp = await getAllBankAccountsDataAPI(data);

      if (resp?.data?.length > 0) {
        setBankAccountData(resp?.data);
      } else {
        setToastMsg({
          msg: resp.data?.msg,
          time: 3000,
          variant: "error",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    fetchData();
  }, [debouncedQuery]);

  const handleChange = (key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveBankAccount = async () => {
    const newErrors = {
      code: !payload.code
        ? "This field is required"
        : !/^[0-9]{4}$/.test(payload.code) ||
          payload.code < 1 ||
          payload.code > 9999
        ? "Code must be a 4-digit number between 0001 and 9999"
        : "",
      bankName: !payload.bankName ? "This field is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (!hasErrors) {
      const userId = loggedinUser?.userId;
      const companyId = loggedinUser?.companyId;

      try {
        const bankAccountPayload = {
          ...payload,
          companyId,
          createdByUserId: userId,
          updatedByUserId: userId,
        };

        const resp = isUpdate
          ? await updateBankAccountAPI({ ...bankAccountPayload, accountId })
          : await addBankAccountAPI(bankAccountPayload);

        if (resp.data.status) {
          setToastMsg({
            msg: resp.data?.msg,
            time: 3000,
            variant: "success",
          });
          setTimeout(() => {
            handleOpen();
          }, 500);
        } else {
          setToastMsg({
            msg: resp.data?.msg,
            time: 3000,
            variant: "error",
          });
          setTimeout(() => {
            handleOpen();
          }, 500);
        }

        setAddUpdateModal(false);
        setTimeout(() => fetchData(), 1000);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleEdit = (data) => {
    setPayload({
      code: data?.code,
      bankName: data?.bankName,
    });
    setAccountId(data.accountId);
    setIsUpdate(true);
    setAddUpdateModal(true);
  };

  const handleDelete = async () => {
    try {
      const resp = await deleteBankAccountAPI(accountId);

      if (resp.data.status) {
        setToastMsg({
          msg: resp.data?.msg,
          time: 3000,
          variant: "success",
        });
        setTimeout(() => {
          handleOpen();
        }, 500);
      } else {
        setToastMsg({
          msg: resp.data?.msg,
          time: 3000,
          variant: "error",
        });
        setTimeout(() => {
          handleOpen();
        }, 500);
      }
      setDeleteBankAccountModal(false);
      setTimeout(() => fetchData(), 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Sidebar />

      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"10px 100px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Stack
        className="bankaccountmanagementpage"
        spacing={1}
        padding={"10px 20px 10px 100px"}
      >
        <Stack
          direction={matches ? "row" : "column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Paper
            component="form"
            sx={{ display: "flex", alignItems: "center", width: 300 }}
          >
            <IconButton disabled={true} aria-label="search">
              <Search />
            </IconButton>
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search Bank Accounts"
              onChange={(e) => setSearchQuery(e.target.value)}
              inputProps={{ "aria-label": "search bank accounts" }}
            />
          </Paper>
          <Button
            className="GreenBTN"
            variant="filled"
            onClick={() => {
              setPayload({
                code: "",
                bankName: "",
              });
              setIsUpdate(false);
              setAddUpdateModal(true);
            }}
          >
            + Add Bank Account
          </Button>
        </Stack>

        <Card style={{ marginTop: "10px" }}>
          <div className="tableLaylout">
            <Table aria-label="sticky table" className="table">
              <TableHead>
                <TableRow className="tablHeadRowPayment">
                  <TableCell className="tablHeadCellPayment">Code</TableCell>
                  <TableCell className="tablHeadCellPayment">Bank Name</TableCell>
                  <TableCell className="tablHeadCellPayment">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankAccountData?.map((data, i) => {
                  const { code, bankName, accountId } = data;

                  return (
                    <TableRow key={i} className="tableRow">
                      <TableCell>{code}</TableCell>
                      <TableCell>{bankName}</TableCell>
                      <TableCell>
                        <Stack direction={"row"} spacing={1}>
                          <IconButton
                            color="primary"
                            aria-label="update"
                            onClick={() => handleEdit(data)}
                          >
                            <EditSharp />
                          </IconButton>

                          <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={() => {
                              setAccountId(accountId);
                              setDeleteBankAccountModal(true);
                            }}
                          >
                            <DeleteSharp />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Card>
      </Stack>

      {/* Delete Bank Account Modal */}
      <Dialog
        open={deleteBankAccountModal}
        onClose={() => setDeleteBankAccountModal(false)}
      >
        <DialogTitle>Delete Bank Account</DialogTitle>
        <DialogContent>
          <Typography fontSize={15} variant="h6">
            Are you sure, You want to delete this bank account record?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="outlined"
              onClick={() => setDeleteBankAccountModal(false)}
            >
              Cancel
            </Button>
            <Button color="error" variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Add/Update Modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={addUpdateModal}
        onClose={() => setAddUpdateModal(false)}
      >
        <DialogTitle>{isUpdate ? "Update" : "Add"} Bank Account</DialogTitle>
        <DialogContent>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
              <Stack spacing={0.5} marginBottom={1}>
                <Typography variant="body1">
                  Code<span style={{ color: "red", fontSize: 15 }}>*</span>
                </Typography>
                <TextField
                  hiddenLabel
                  inputMode="numeric"
                  type="number"
                  value={payload.code}
                  placeholder="Enter Code"
                  onChange={(e) => handleChange("code", e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <Typography style={{ fontSize: "14px" }}>
                  Code can be any number between 0001 and 9999
                </Typography>
                {errors.code && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors.code}
                  </Typography>
                )}
              </Stack>
              <Stack spacing={0.5} marginBottom={1}>
                <Typography variant="body1">
                  Bank Name<span style={{ color: "red", fontSize: 15 }}>*</span>
                </Typography>
                <TextField
                  hiddenLabel
                  value={payload.bankName}
                  placeholder="Enter Bank Name"
                  onChange={(e) => handleChange("bankName", e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                {errors.bankName && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors.bankName}
                  </Typography>
                )}
              </Stack>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" onClick={() => setAddUpdateModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveBankAccount}>
              {isUpdate ? "Update" : "Add"}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BankAccountManagement;
