import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Box,
  Grid,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Paper,
  IconButton,
  InputBase,
} from "@mui/material";
import Select from "react-select";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import { BaseUrl } from "../../Environment";
import { Search } from "@mui/icons-material";
import "./LoanDisbursement.scss";
import moment from "moment";
import Proptypes from "prop-types";
import CancelLoan from "../modals/CancelLoan/CancelLoan";

const LoanDisbursement = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  function convertToISOString(dateString) {
    if (!dateString || typeof dateString !== "string") {
      throw new Error("Invalid input: dateString must be a non-empty string");
    }

    const [day, month, year] = dateString.split("-").map(Number);

    // Create a Date object using the parsed values and set the time to noon (12:00:00)
    const dateObject = new Date(year, month - 1, day, 12, 0, 0);

    // Check if the date is valid
    if (isNaN(dateObject.getTime())) {
      throw new RangeError("Invalid date");
    }

    // Convert to ISO 8601 format (UTC time)
    return dateObject.toISOString();
  }

  const [open, setOpen] = useState(false);
  const [enabledDisburse, setEnabledDisburse] = useState(false);
  const [disburseDataModal, setDisburseDataModal] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loanData, setLoanData] = useState([]);
  const [loanDetailsData, setLoanDetailsData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });
  const [fiDate, setFiDate] = useState(moment());
  const [disbursedDate, setDisbursedDate] = useState(moment());
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelLoanId, setCancelLoanId] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getCustomersList = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const companyId = localStorage.getItem("loggedinUserCompany");
      const headers = { Authorization: `Bearer ${token}` };

      const { data, status } = await Axios.post(
        `${BaseUrl}/customer/getCustomersList`,
        { companyId, onlyVerified: true, searchQuery: "" },
        { headers }
      );

      if (status === 200) {
        const cList = data?.result?.map((item) => ({
          label: item?.cust_id
            ? `${item.firstName} ${item.lastName} (${item.cust_id})`
            : `${item.firstName} ${item.lastName}`,
          value: item.customerId,
        }));
        setCustomerData(cList);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getAllLoansforDisburse = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const companyId = localStorage.getItem("loggedinUserCompany");
      const headers = { Authorization: `Bearer ${token}` };

      const { data, status } = await Axios.post(
        `${BaseUrl}/loan/getAllLoansforDisburse`,
        { companyId, search: debouncedQuery },
        { headers }
      );

      if (status === 200) setLoanData(data);
    } catch (err) {
      console.error(err);
    }
  }, [debouncedQuery]);

  const getLoanDetailsforDisburse = useCallback(async (selectedLoanId) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const headers = { Authorization: `Bearer ${token}` };

      const { data, status } = await Axios.get(
        `${BaseUrl}/loan/getLoanDetailsforDisburse`,
        { params: { loanId: selectedLoanId }, headers }
      );

      if (status === 200) {
        setLoanDetailsData(data);
        setFiDate(moment(data?.approvalDate).format("YYYY-MM-DD"));
        setDisburseDataModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedQuery(searchQuery), 500);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    getCustomersList();
  }, [getCustomersList]);

  useEffect(() => {
    getAllLoansforDisburse();
  }, [debouncedQuery, getAllLoansforDisburse, showCancelModal]);

  const approveLoan = useCallback(async (selectedLoanId) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const headers = { Authorization: `Bearer ${token}` };

      const { data, status } = await Axios.put(
        `${BaseUrl}/loan/approveLoan`,
        { loanId: selectedLoanId, approvalDate: new Date() },
        { headers }
      );

      if (status === 200) {
        if (data?.status) {
          setToastMsg({
            msg: data?.msg,
            time: 3000,
            variant: "success",
          });

          getAllLoansforDisburse();

          setTimeout(() => {
            handleOpen();
          }, 500);

          setEnabledDisburse(true);
        }
        // console.log("approveLoan", data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const setFiDateFunc = (date) => {
    try {
      const formattedDate = moment(date).format("DD-MM-YYYY").toString();
      const isoDate = convertToISOString(formattedDate);

      setFiDate(isoDate);
    } catch (error) {
      console.error(error.message); // Handle invalid date error
    }
  };

  const setDisbursedDateFunc = (date) => {
    try {
      const formattedDate = moment(date).format("DD-MM-YYYY").toString();
      const isoDate = convertToISOString(formattedDate);

      setDisbursedDate(isoDate);
    } catch (error) {
      console.error(error.message); // Handle invalid date error
    }
  };

  const disburseLoan = useCallback(
    async (selectedLoanId, fiDate, disbursedDate) => {
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        const headers = { Authorization: `Bearer ${token}` };

        const { data, status } = await Axios.put(
          `${BaseUrl}/loan/disburseLoan`,
          {
            loanId: selectedLoanId,
            disbursedDate: disbursedDate,
            fiDate: fiDate,
          },
          { headers }
        );

        if (status === 200) {
          if (data?.status) {
            setToastMsg({
              msg: data?.msg,
              time: 3000,
              variant: "success",
            });

            getAllLoansforDisburse();
            setEnabledDisburse(false);

            setTimeout(() => {
              handleOpen();
            }, 500);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    []
  );

  const InputField = ({ label, value, type }) => (
    <Grid item xs={12} sm={6} md={6} paddingX={2}>
      <label>{label}</label>
      <input
        type={type ? type : "text"}
        value={value}
        disabled={type ? false : true}
        onChange={(e) =>
          label == "Disbursement Date"
            ? setDisbursedDateFunc(e.target.value)
            : setFiDateFunc(e.target.value)
        }
      />
    </Grid>
  );

  const Fieldset = ({ legend, fields }) => (
    <fieldset
      style={{
        marginTop: "10px",
        border: "1px solid #c3c3c3",
        padding: "10px",
      }}
    >
      <legend>{legend}</legend>
      <Grid container>
        {fields.map(({ label, value, type }, index) => (
          <InputField key={index} label={label} value={value} type={type} />
        ))}
      </Grid>
    </fieldset>
  );

  InputField.prototype = {
    key: Proptypes.any,
    label: Proptypes.any,
    value: Proptypes.any,
    type: Proptypes.any,
  };

  const getAddress = (address) =>
    [
      address?.AddressStreet,
      address?.AddressLandmark,
      address?.AddressCity,
      address?.AddressState,
      address?.AddressPincode,
    ]
      .filter(Boolean)
      .join(", ");

  const toggleCancelPopup = () => {
    setShowCancelModal(!showCancelModal);
  };

  return (
    <>
      <Sidebar />
      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="10px 100px"
      >
        <img src={companylogo} alt="logo" />
      </Stack>
      <Stack
        className="assetmanagementpage"
        spacing={1}
        padding="10px 20px 10px 100px"
      >
        <Stack
          direction={matches ? "row" : "column"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Paper
            component="form"
            sx={{ display: "flex", alignItems: "center", width: 300 }}
          >
            <IconButton disabled aria-label="search">
              <Search />
            </IconButton>
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search Loans"
              onChange={(e) => setSearchQuery(e.target.value)}
              inputProps={{ "aria-label": "search loans" }}
            />
          </Paper>
          <Stack style={{ minWidth: "300px", marginTop: matches ? 0 : "10px" }}>
            <Select
              options={customerData}
              value={customerData.find(
                (item) => item.value === selectedCustomer
              )}
              onChange={(val) => setSelectedCustomer(val?.value)}
              placeholder="Select Customers"
            />
          </Stack>
        </Stack>

        <Card style={{ marginTop: "10px" }}>
          <div className="tableLaylout">
            <Table aria-label="sticky table" className="table">
              <TableHead>
                <TableRow className="tablHeadRowPayment">
                  <TableCell className="tablHeadCellPayment">Sr. No</TableCell>
                  <TableCell className="tablHeadCellPayment">
                    Customer Name
                  </TableCell>
                  <TableCell className="tablHeadCellPayment">Loan ID</TableCell>
                  <TableCell className="tablHeadCellPayment">
                    Loan Amount
                  </TableCell>
                  <TableCell className="tablHeadCellPayment">
                    Loan Type
                  </TableCell>
                  <TableCell className="tablHeadCellPayment">
                    Loan Method
                  </TableCell>

                  <TableCell className="tablHeadCellPayment">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loanData.map((data, idx) => (
                  <TableRow key={idx} className="tableRow">
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{`${data?.customer?.firstName} ${data?.customer?.lastName} (${data?.customer?.cust_id})`}</TableCell>
                    <TableCell>{data?.loanId}</TableCell>
                    <TableCell>{data?.loanAmount}</TableCell>
                    <TableCell>{data?.loanType?.loanType}</TableCell>
                    <TableCell>{data?.method}</TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor:
                              data?.loanStatus === "Approved" ?? enabledDisburse
                                ? "gray"
                                : "#c10707",
                            color: "#fff",
                          }}
                          disabled={
                            data?.loanStatus === "Approved" ?? enabledDisburse
                              ? true
                              : false
                          }
                          onClick={() => {
                            setCancelLoanId(data?.loanId)
                            toggleCancelPopup()
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor:
                              data?.loanStatus === "Approved" ?? enabledDisburse
                                ? "gray"
                                : "#00D95E",
                            color: "#fff",
                          }}
                          disabled={
                            data?.loanStatus === "Approved" ?? enabledDisburse
                              ? true
                              : false
                          }
                          onClick={() => {
                            approveLoan(data?.loanId);
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor:
                              data?.loanStatus === "Approved" ?? enabledDisburse
                                ? "#00D95E"
                                : "gray",
                            color: "#fff",
                          }}
                          disabled={
                            data?.loanStatus === "Approved" ?? enabledDisburse
                              ? false
                              : true
                          }
                          onClick={() =>
                            getLoanDetailsforDisburse(data?.loanId)
                          }
                        >
                          Disburse
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </Stack>

      {/* Add/Update Modal */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={disburseDataModal}
        onClose={() => setDisburseDataModal(false)}
      >
        <DialogTitle>Disbursement</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Fieldset
              legend="Customer Details"
              fields={[
                {
                  label: "Customer Name",
                  value: `${loanDetailsData?.customer?.firstName} ${loanDetailsData?.customer?.lastName}`,
                },
                {
                  label: "Loan Application ID",
                  value: loanDetailsData?.loanId,
                },
                {
                  label: "Mobile Number",
                  value: loanDetailsData?.customer?.phone,
                },
                { label: "Email ID", value: loanDetailsData?.customer?.email },
                {
                  label: "Aadhaar No",
                  value: loanDetailsData?.customer?.aadhar,
                },
                { label: "PAN No", value: loanDetailsData?.customer?.pan },
                {
                  label: "Address",
                  value: getAddress(loanDetailsData?.customer?.addresses[0]),
                },
              ]}
            />

            <Fieldset
              legend="Co-Applicant Details"
              fields={[
                {
                  label: "Co. Applicant Name",
                  value:
                    loanDetailsData?.customer?.coApplicant?.coApplicantName,
                },
                {
                  label: "Contact No",
                  value:
                    loanDetailsData?.customer?.coApplicant?.coApplicantMobNo,
                },
                {
                  label: "Relationship",
                  value:
                    loanDetailsData?.customer?.coApplicant?.coApplicantRelation,
                },
                {
                  label: "Address",
                  value:
                    loanDetailsData?.customer?.coApplicant?.coApplicantAddress,
                },
                {
                  label: "PAN No",
                  value: loanDetailsData?.customer?.coApplicant?.coApplicantPAN,
                },
                {
                  label: "Aadhaar No",
                  value:
                    loanDetailsData?.customer?.coApplicant?.coApplicantAADHAR,
                },
              ]}
            />

            <Fieldset
              legend="Bank Details"
              fields={[
                {
                  label: "Bank Account No",
                  value: loanDetailsData?.customer?.bankDetail?.accountNumber,
                },
                {
                  label: "IFSC Code",
                  value: loanDetailsData?.customer?.bankDetail?.ifsc,
                },
                {
                  label: "Branch Name",
                  value: loanDetailsData?.customer?.bankDetail?.bankBranch,
                },
                {
                  label: "Account Holder Name",
                  value: loanDetailsData?.customer?.bankDetail?.acHolderName,
                },
              ]}
            />

            <Fieldset
              legend="Loan Details"
              fields={[
                { label: "Loan Amount", value: loanDetailsData?.loanAmount },
                { label: "Processing Fee", value: loanDetailsData?.processFee },
                {
                  label: "Loan Type",
                  value: loanDetailsData?.loanType?.loanType,
                },
                {
                  label: "EMI Start Date",
                  value: moment(loanDetailsData?.startDate).format(
                    "DD-MM-YYYY"
                  ),
                },
                { label: "Tenure", value: loanDetailsData?.loanTenure },
                {
                  label: "Source Name",
                  value: `${loanDetailsData?.customer?.user?.firstName ?? ""} ${
                    loanDetailsData?.customer?.user?.lastName ?? ""
                  }`,
                },
                {
                  label: "Disbursement Date",
                  value: moment(disbursedDate).format("YYYY-MM-DD"),
                  type: "date",
                },
                {
                  label: "Source ID",
                  value: loanDetailsData?.customer?.user?.empId,
                },
                {
                  label: "Disbursement Amount",
                  value: loanDetailsData?.loanAmount,
                },
                {
                  label: "F.I Date",
                  value: moment(fiDate).format("YYYY-MM-DD"),
                  type: "date",
                },
              ]}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDisburseDataModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                disburseLoan(loanDetailsData?.loanId, fiDate, disbursedDate);
                setDisburseDataModal(false);
              }}
            >
              Disburse
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <CancelLoan
        loanId={cancelLoanId}
        open={showCancelModal}
        cancel={toggleCancelPopup}
        action={() => {}}
      />
    </>
  );
};

export default LoanDisbursement;
