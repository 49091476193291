const getBaseUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
      // return "https://lendstack.app/";
      // return "http://167.71.234.126/";
      // return "http://143.110.188.7:4001/";
      return 'http://localhost:4000/';
  }
  return '/';
}

export const ImageBaseUrl = getBaseUrl();
export const api = "fintech/v1/api".trim();
export const BaseUrl = getBaseUrl()+ api;
export const headers = {
  Authorization: "Bearer " + localStorage.getItem("token"),
};

export const currencyFormat = "en-IN";

// http://52.172.186.62/
