import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import loginimage from '../../assets/images/loginimage.svg';
import './contact.scss';
import { toast } from 'react-toastify';
import GooglePlay from '../../assets/images/Group 28315.svg';
import AppStore from '../../assets/images/Group 28316.svg';
import msgimag from '../../assets/images/msgimag.svg';
import messageblack from '../../assets/images/messageblack.svg';
import 'react-toastify/dist/ReactToastify.css';
import callIcon from '../../assets/images/call.svg';
import emailicon from '../../assets/images/Email.svg';
import location from '../../assets/images/location.svg';
class Contact extends Component {
    constructor() {
        super();
        this.state = {
        }
    }  

    componentDidMount() {
        setInterval(() => {
            this.setState({
                curTime: new Date().toLocaleString()
            })
        }, 1000)

    } 
   

    render() {
        toast.configure({});        
        return (
            <div className="contactPage">
                <div className="mainDiv">
                    <div style={{ marginTop: "4%", display:'flex' }}>
                        <div style={{marginLeft: 'auto'}}>
                        <div className="firstDiv">
                            <div className="firstDivOne">
                                <div className="firstH3 TextColour">Collect like a PRO!</div>
                                <div className="firstH4 TextColour">Digital Bharat ka Digital Khata</div>
                            </div>
                        </div>
                        <div className="secondDiv">
                            <div className="ImageDiv">
                                <img src={loginimage} alt="login" className="loginIMage" />
                                <div className="secondH4">Digital Ledger Account (Udhar Bahi Khata book) for Credit, Lending and Hisab Khitab for small lenders, grocers and shopkeepers</div>
                                <div className="storeButtons">
                                    <div className="storeButtonsOne">
                                        <div className="googlePlay">
                                            <img src={GooglePlay} alt="googlePlay" className="googlePlay" />
                                        </div>
                                        <div className="appStore">
                                            <img src={AppStore} alt="appStore" className="appStore" />
                                        </div>
                                    </div>
                                    <div className="clickHere">
                                    Read our <a target="_blank" href='https://lendstack.app/#/termsofservice'>Terms of Service</a> and <a target="_blank" href='https://lendstack.app/#/privacypolicy'>Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="CardDiv">
                                <div className="CardDiv2">
                                    <Card className="CardDiv3">
                                        <div className="CardDiv4">
                                            <div className="CardDiv5 HighlightTextColour">
                                                <h3 className="Fonts">We love it when you reach out to us</h3>
                                            </div>
                                            <div className="CardDiv6"><img src={msgimag} alt="msgimag" className="msgImage" style={{ alignSelf: 'center' }} /></div>
                                            <div className="MobileNumberDiv"><img src={emailicon} alt="emailicon" className="emailIcon"></img><h5 className="Fonts TextColour SizeFont">cofounder@lendstack.app</h5></div>
                                            <div className="MobileNumberDiv"><img src={callIcon} alt="callIcon" className="callIcon"></img><h5 className="Fonts TextColour SizeFont">+91-9923055180</h5></div>
                                            <div className="MobileNumberDiv"><img src={location} alt="location" className="callIcon"></img><h5 className="Fonts TextColour SizeFont"> Address:Shree Shanti Nagar Society, Gangadham Kondhwa Road, Kondhwa BK, Pune, Maharashtra 411048</h5></div>
                                        {/* <div className="CardDiv6"><b>Call us:+91-9923055180</b></div>   */}                                        
                                        </div>
                                    </Card>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Contact