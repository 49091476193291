import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import loginimage from '../../assets/images/loginimage.svg';
import './aboutus.scss';
import '../../index.scss';
import { toast } from 'react-toastify';
import GooglePlay from '../../assets/images/Group 28315.svg';
import AppStore from '../../assets/images/Group 28316.svg';
import 'react-toastify/dist/ReactToastify.css';
class About extends Component {
    constructor() {
        super();
        this.state = {};
    }   

    componentDidMount() {
        setInterval(() => {
            this.setState({
                curTime: new Date().toLocaleString()
            })
        }, 1000)

    }

    render() {
        toast.configure({});  
        return (
            <div className="aboutusPage">
                <div className="mainDiv">
                    <div style={{ marginTop: "4%", display:'flex' }}>
                        <div style={{marginLeft: 'auto'}}>
                        <div className="firstDiv">
                            <div className="firstDivOne">
                                <div className="firstH3 TextColour">Collect like a PRO!</div>
                                <div className="firstH4 TextColour">Digital Bharat ka Digital Khata</div>
                            </div>
                        </div>
                        <div className="secondDiv">
                            <div className="ImageDiv">
                                <img src={loginimage} alt="login" className="loginIMage" />
                                <div className="secondH4">Digital Ledger Account (Udhar Bahi Khata book) for Credit, Lending and Hisab Khitab for small lenders, grocers and shopkeepers</div>
                                <div className="storeButtons">
                                    <div className="storeButtonsOne">
                                        <div className="googlePlay">
                                            <img src={GooglePlay} alt="googlePlay" className="googlePlay" />
                                        </div>
                                        <div className="appStore">
                                            <img src={AppStore} alt="appStore" className="appStore" />
                                        </div>
                                    </div>
                                    <div className="clickHere">
                                    Read our <a target="_blank" href='https://lendstack.app/#/termsofservice'>Terms of Service</a> and <a target="_blank" href='https://lendstack.app/#/privacypolicy'>Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="CardDiv">
                                <div className="CardDiv2">
                                    <Card className="CardDiv3">
                                        <div className="CardDiv4">
                                            <div className="CardDiv5 HighlightTextColour">
                                                <h3 className="Fonts">About Us</h3>
                                            </div>
                                        <div style={{fontFamily: 'Lato',textAlign: 'left',margin:2}}>
                                        Our company <b>WHAT TO FABRIKAT OÜ</b> is a light-hearted take on "What The Fun" with a vision to enable fun in doing business for various types of businesses like Kirana store owners, 
                                        small-time lenders, metal fabricators, grocery shops, medical stores, and tuition teachers among others across Bharat.  We decided to share the fun with the world by creating our very 
                                        own <b>VasoolRaja</b> called <b>LendStack!</b><br></br>
                                        <br></br><b>Our Mission is to empower micro-entrepreneurs digitally with Zero fuss, Zero flex, and Zero flux.</b><br></br><br></br>
                                        <b>Founding Story</b><br></br>
                                        In early 2021, we wanted to turn traditional lending and bookkeeping - 'Khata', into a fully digital process that tracks payments as well as receivables. The app not only helps small lenders and
                                        businesses to schedule and keep track of credit transactions – both payments and receivables. It also makes it easier for them to collect and settle balance amounts quickly and accurately. It also offers features 
                                        such as online payment collection through UPI and even sending periodic reminders to creditors via WhatsApp and SMS messages.<br></br>
                                      <br></br><b>Culture</b><br></br>
                                       At our core, we believe in Digital Bharat. We celebrate small wins and grin wide on big mistakes – learn from them and give our best every day to make our product a bit more refined, a bit more — Antifragile!<br></br><br></br>
                                       Finally, we love to hear from you — comments, criticisms, suggestions, bugs, or hate speeches(!) you’re welcome to shoot us an email at cofounder@lendstack.app<br></br>
                                       </div> 
                                        </div>
                                    </Card>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default About