import axios from "axios";
import { BaseUrl } from "../Environment";

// getUserDetails
const getUserDetails = async (firebaseUid) => {
  try {
    // /user/getLoggedInUserDetails?firebaseUid=123
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      return null;
    }
    const headers = {
      Authorization: "Bearer " + token,
    };

    const res = await axios.get(
      BaseUrl + "/user/getLoggedInUserDetails?firebaseUid=" + firebaseUid,
      {
        headers: headers,
      }
    );

    return res?.data;
  } catch (error) {
    return null;
  }
};
const checkUser = async (firebaseUid, EmailID) => {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      return null;
    }
    const headers = {
      Authorization: "Bearer " + token,
    };

    const res = await axios.get(
      BaseUrl + `/user/checkUser?firebaseUid=${firebaseUid}&EmailID=${EmailID}`,
      {
        headers: headers,
      }
    );

    return res?.data;
  } catch (error) {
    return null;
  }
};

const getCompanyProfile = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
    if (!token) {
      return null;
    }
    const headers = {
      Authorization: "Bearer " + token,
    };

    const res = await axios.get(
      BaseUrl +
        "/company/getCompanyProfile?companyId=" +
        loggedinUser.companyId,
      {
        headers: headers,
      }
    );

    return res?.data;
  } catch (error) {
    return null;
  }
};

const notPaidLoginCheck = async (userId) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
  };
  axios
    .put(
      BaseUrl +
        "/loanCollection/notPaidLoginCheck",
        {userId: userId},
      {
        headers: headers,
      }
    )
    .then((resp) => {
      console.log({resp});
    })
    .catch((err) => {
      console.log(err);
    });
}

const updateSessionUser = async (userData) => {
  // /user/updateSessionUser
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      return null;
    }

    const headers = {
      Authorization: "Bearer " + token,
    };
    const res = await axios.put(BaseUrl + "/user/updateSessionUser", userData, {
      headers: headers,
    });

    console.log("res", res?.status, res?.data);
    return res;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
const creategoogleuser = async (userData) => {
  // /user/updateSessionUser
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      return null;
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const res = await axios.post(BaseUrl + "/user/creategoogleuser", userData, {
      headers: headers,
    });

    console.log("res", res?.status, res?.data);
    return res?.status;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export {
  getCompanyProfile,
  getUserDetails,
  updateSessionUser,
  creategoogleuser,
  checkUser,
  notPaidLoginCheck
};
