import React, { useState, useCallback, useRef, useEffect } from "react";
import { Stack, Grid, Button, Box } from "@mui/material";
import { Print, RestoreOutlined, ViewList } from "@mui/icons-material";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import Axios from "axios";

import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import { BaseUrl } from "../../Environment";
import "./PrintDocs.css";

import { LoanAgreement } from "../LoanAgreement/LoanAgreement";
import { LoanApplication } from "../LoanApplication/LoanApplication";
import { LoanSanctionLetter } from "../LoanSanctionLetter/LoanSanctionLetter";
import { DemandPromissoryNote } from "../DemandPromissoryNote/DemandPromissoryNote";
import { EmiCard } from "../EmiCard/EmiCard";

const docTypes = [
  { label: "Loan Application Form", value: "1" },
  { label: "Loan Agreement", value: "2" },
  { label: "Loan Sanction Letter", value: "3" },
  { label: "EMI  Card", value: "4" },
  { label: "Demand Promissory Note", value: "5" },
];

const PrintDocs = () => {
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const [selectedDocType, setSelectedDocType] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loanData, setLoanData] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [renderedComponent, setRenderedComponent] = useState(null);
  const [pdfData, setPdfData] = useState([]);

  const getAllCustomers = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const companyId = localStorage.getItem("loggedinUserCompany");
    let collectorId = loggedinUser?.userId;

    const headers = {
      Authorization: "Bearer " + token,
    };

    const payload = {
      companyId,
      collectorId,
      userType: loggedinUser?.userType,
      fullData: true,
    };

    Axios.post(BaseUrl + "/customer/getAllCustomersMobile", payload, {
      headers: headers,
    })
      .then((resp) => {
        if (resp.status == 200) {
          const cList = resp.data?.result?.map((item) => ({
            label: item?.cust_id
              ? `${item.firstName} ${item.lastName} (${item.cust_id})`
              : `${item.firstName} ${item.lastName}`,
            value: item.customerId,
          }));
          setCustomerData(cList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoanApplicationDetails = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get(
      BaseUrl + `/loan/getLoanApplicationDetails?loanId=${selectedLoan?.value}`,
      {
        headers: headers,
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          setPdfData(resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoanApplicationCustomer = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get(
      BaseUrl +
        `/loan/getLoanApplicationCustomer?customerId=${selectedCustomer?.value}`,
      {
        headers: headers,
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          setPdfData(resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoanAgreementDetails = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get(
      BaseUrl + `/loan/getLoanAgreementDetails?loanId=${selectedLoan?.value}`,
      {
        headers: headers,
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          setPdfData(resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPromissoryNote = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get(
      BaseUrl +
        `/customer/getPromissoryNote?customerId=${selectedCustomer?.value}&loanId=${selectedLoan?.value}`,
      {
        headers: headers,
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          setPdfData(resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllLoans = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const companyId = localStorage.getItem("loggedinUserCompany");

    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get(
      BaseUrl +
        `/loan/getLoansforCustomers?customerId=${selectedCustomer?.value}&companyId=${companyId}`,
      {
        headers: headers,
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          const loanIds = resp.data?.map((item) => ({
            label: item.loanId,
            value: item.loanId,
          }));
          setLoanData(loanIds);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      getAllLoans();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    setPdfData([]);
    if (selectedDocType && selectedCustomer) {
      if (selectedDocType?.value === "1") {
        if (selectedLoan) {
          getLoanApplicationDetails();
        } else {
          getLoanApplicationCustomer();
        }
      } else if (
        selectedDocType?.value === "2" ||
        selectedDocType?.value === "3" ||
        selectedDocType?.value === "4"
      ) {
        if (selectedLoan) {
          getLoanAgreementDetails();
        }
      } else if (selectedLoan) {
        getPromissoryNote();
      }
    }
  }, [selectedCustomer, selectedLoan]);

  const handleDocTypeChange = useCallback((val) => {
    setSelectedDocType(val);
    setSelectedCustomer(null);
    setSelectedLoan(null);
    setRenderedComponent(null);
  }, []);

  const handleCustomerChange = useCallback((val) => {
    setSelectedCustomer(val);
    setSelectedLoan(null);
  }, []);

  const handleLoanChange = useCallback((val) => {
    setSelectedLoan(val);
  }, []);

  const handleReset = () => {
    setSelectedDocType(null);
    setSelectedCustomer(null);
    setSelectedLoan(null);
    setRenderedComponent(null);
  };

  const handleSubmit = () => {
    if (selectedDocType && selectedCustomer) {
      if (selectedDocType.value === "1") {
        if (selectedLoan) {
          setRenderedComponent(
            <LoanApplication data={pdfData} printRef={printRef} />
          );
        } else {
          setRenderedComponent(
            <LoanApplication data={pdfData} printRef={printRef} />
          );
        }
      } else if (selectedLoan) {
        if (selectedDocType.value === "2") {
          setRenderedComponent(
            <LoanAgreement data={pdfData} printRef={printRef} />
          );
        } else if (selectedDocType.value === "3") {
          setRenderedComponent(
            <LoanSanctionLetter data={pdfData} printRef={printRef} />
          );
        } else if (selectedDocType.value === "4") {
          setRenderedComponent(<EmiCard data={pdfData} printRef={printRef} />);
        } else {
          setRenderedComponent(
            <DemandPromissoryNote data={pdfData} printRef={printRef} />
          );
        }
      }
    }
  };

  return (
    <>
      <Sidebar />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="paddingSidebar"
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Stack spacing={1} className="paddingSidebar">
        <Box
          className="print-docs"
          sx={{
            width: "70%",
            paddingInline: "10px",
            paddingTop: "20px",
            backgroundColor: "#fff",
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} sx={{ padding: "0 10px" }}>
              <Stack spacing={0.5} marginBottom={2}>
                <strong>Document Type</strong>
                <Select
                  options={docTypes}
                  value={selectedDocType}
                  onChange={handleDocTypeChange}
                  placeholder="Select Document Type"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 10px" }}>
              <Stack spacing={0.5} marginBottom={2}>
                <strong>Customers</strong>
                <Select
                  options={customerData}
                  isDisabled={!selectedDocType}
                  value={selectedCustomer}
                  onChange={handleCustomerChange}
                  placeholder="Select Customer"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 10px" }}>
              <Stack spacing={0.5} marginBottom={2}>
                <strong>Loans</strong>
                <Select
                  options={loanData}
                  isDisabled={!selectedCustomer}
                  value={selectedLoan}
                  onChange={handleLoanChange}
                  placeholder="Select Loan"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 10px", marginBottom: "20px" }}>
              <Stack direction="row" spacing={5} alignItems="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<RestoreOutlined />}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<ViewList />}
                  onClick={handleSubmit}
                  disabled={
                    selectedDocType && selectedCustomer
                      ? selectedDocType.value === "1" || selectedLoan
                        ? false
                        : true
                      : true
                  }
                >
                  Show PDF
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      {/* Render the selected component */}
      {renderedComponent && (
        <Box ref={printRef} className="paddingSidebar">
          {renderedComponent}
        </Box>
      )}

      <div
        style={{ display: "flex", justifyContent: "center", marginBlock: 10 }}
      >
        <Button
          variant="outlined"
          style={{ minWidth: 200, marginBottom: "20px" }}
          startIcon={<Print />}
          onClick={handlePrint}
          disabled={!renderedComponent}
        >
          Print
        </Button>
      </div>
    </>
  );
};

export default PrintDocs;
