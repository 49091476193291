import React from "react";
import "./LoanApplication.css";
import { Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { ImageBaseUrl } from "../../Environment";
import moment from "moment";

const InputField = ({ label, value, name, size }) => (
  <Grid item xs={12} sm={size ? size : 4} md={size ? size : 4} paddingX={2}>
    <label>{label}: </label>
    <input type="text" value={value} disabled={true} name={name} />
  </Grid>
);

InputField.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.any,
  size: PropTypes.any,
};

export const LoanApplication = ({ data, printRef }) => {
  const companyData = data?.company;
  const customerData = data?.customer ?? data;
  const customerDataAddresses = data?.customer?.addresses[0] ?? data?.addresses[0];
  const customerDataBankDetail = data?.customer?.bankDetail ?? data?.bankDetail;
  const customerDataCoApplicant = data?.customer?.coApplicant ?? data?.coApplicant;
  const customerDataNominee = data?.customer?.nominee ?? data?.nominee;

  const { loanType, loanTenure, collectionAmount, loanAmount } = data;

  const getAddress = (address) =>
    [
      address?.AddressStreet,
      address?.AddressLandmark,
      address?.AddressCity,
      address?.AddressState,
      address?.AddressPincode,
    ]
      .filter(Boolean)
      .join(", ");

  return (
    <div className="loan-application-form" ref={printRef}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black" }}
        >
          <div className="header-left">
            {companyData?.profilePic ? (
              <img
                src={
                  localStorage.getItem("loggedinUserCompany") == "1365"
                    ? "https://media.licdn.com/dms/image/v2/D4E03AQEYHVC65N8MyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707325481532?e=2147483647&v=beta&t=fnBgoLCTop9Pd1IJQfzhVnknSIrgixpvhvkEmfBOhpo"
                    : `${ImageBaseUrl}${companyData?.profilePic}`
                }
                alt="Logo"
                className="logo"
              />
            ) : (
              <div
                style={{
                  height: "120px",
                }}
              ></div>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          paddingX={2}
          style={{ border: "1px solid black", borderRightWidth: "0px" }}
        >
          <div className="header-right">
            <h1>{companyData?.companyName}</h1>
            <p>
              <strong>Address: </strong> {getAddress(companyData)}
              <br />
              <strong>GSTIN: </strong> {companyData?.Gstin} |{" "}
              <strong>Website: </strong> {companyData?.companyWebsite}
              <br />
              <strong>Mobile No: </strong> {companyData?.phone}{" "}
              <strong>| Email ID: </strong> {companyData?.companyMail}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black", borderLeftWidth: "0px" }}
        />
      </Grid>
      <h2 className="title">Loan Application Form</h2>
      {/* Loan Details Section */}
      <fieldset
        style={{
          marginTop: "10px",
          border: "1px solid #c3c3c3",
          padding: "10px",
        }}
      >
        <legend>Loan Details</legend>
        <Grid container>
          <InputField
            label="Loan Type"
            value={loanType?.loanType}
            name="loanType"
            size={3.5}
          />
          <InputField
            label="Loan Amount"
            value={loanAmount}
            name="loanAmount"
            size={3.5}
          />
          <InputField
            label="Loan Tenure"
            value={loanTenure}
            name="loanTenure"
            size={2.5}
          />
          <InputField
            label="Loan EMI"
            value={collectionAmount}
            name="loanEMI"
            size={2.5}
          />
        </Grid>
      </fieldset>
      {/* Personal Details Section */}
      <fieldset
        style={{
          marginTop: "10px",
          border: "1px solid #c3c3c3",
          padding: "10px",
        }}
      >
        <legend>Personal Details</legend>
        <Grid container>
          <InputField
            label="First Name"
            value={customerData?.firstName}
            name="firstName"
          />
          <InputField
            label="Last Name"
            value={customerData?.lastName}
            name="lastName"
          />
          <InputField
            label="Mobile No"
            value={customerData?.phone}
            name="mobileNo"
          />
          <InputField
            label="Email ID"
            value={customerData?.email}
            name="emailId"
          />
          <InputField
            label="Customer ID"
            value={customerData?.cust_id}
            name="customerId"
          />
          <InputField
            label="DSA/Staff Id"
            value={
              customerData?.user?.empId
                ? `${customerData?.user?.firstName} ${customerData?.user?.lastName} (${customerData?.user?.empId})`
                : customerData?.user?.firstName
                ? `${customerData?.user?.firstName} ${customerData?.user?.lastName}`
                : ""
            }
            name="staffId"
          />
          <InputField
            label="Date Of Birth (DOB)"
            value={moment(customerData?.dob).format("DD-MM-YYYY")}
            name="dob"
          />
          <InputField
            label="Gender"
            value={customerData?.gender}
            name="gender"
          />
          <InputField
            label="Mother's Name"
            value={customerData?.motherName}
            name="motherName"
          />
          <InputField
            label="Father's Name"
            value={customerData?.fatherName}
            name="fatherName"
          />
          <InputField
            label="Annual Income"
            value={customerData?.annualIncome}
            name="annualIncome"
          />
          <InputField
            label="Major Source of Income"
            value={customerData?.incomeSource}
            name="sourceOfIncome"
          />
          <InputField
            label="AADHAR Card Number"
            value={customerData?.aadhar}
            name="aadharNo"
          />
          <InputField
            label="PAN Card Number"
            value={customerData?.pan}
            name="panNo"
          />
          <Grid item xs={12} sm={12} md={12} paddingX={2}>
            <label>Address: </label>
            <input
              type="text"
              value={getAddress(customerDataAddresses)}
              disabled={true}
              name="address"
            />
          </Grid>
        </Grid>
      </fieldset>
      {/* Co-Applicant Details Section */}
      <fieldset
        style={{
          marginTop: "10px",
          border: "1px solid #c3c3c3",
          padding: "10px",
        }}
      >
        <legend>Co-Applicant Details</legend>
        <Grid container>
          <InputField
            label="Co-Applicant Name"
            value={customerDataCoApplicant?.coApplicantName}
            name="coApplicantName"
          />
          <InputField
            label="Co-Applicant Mobile No"
            value={customerDataCoApplicant?.coApplicantMobNo}
            name="coApplicantMobileNo"
          />
          <InputField
            label="Co-Applicant Address"
            value={customerDataCoApplicant?.coApplicantAddress}
            name="coApplicantAddress"
          />
          <InputField
            label="Co-Applicant Relation"
            value={customerDataCoApplicant?.coApplicantRelation}
            name="coApplicantRelation"
          />
          <InputField
            label="PAN Card Number"
            value={customerDataCoApplicant?.coApplicantPAN}
            name="coApplicantPAN"
          />
          <InputField
            label="AADHAR Card Number"
            value={customerDataCoApplicant?.coApplicantAADHAR}
            name="coApplicantAADHAR"
          />
        </Grid>
      </fieldset>
      {/* Nominee Details Section */}
      <fieldset
        style={{
          marginTop: "10px",
          border: "1px solid #c3c3c3",
          padding: "10px",
        }}
      >
        <legend>Nominee Details</legend>
        <Grid container>
          <InputField
            label="Nominee Name"
            value={customerDataNominee?.nomineeName}
            name="nomineeName"
            size={2.5}
          />
          <InputField
            label="Date Of Birth"
            value={moment(customerDataNominee?.nomineeDOB).format("DD-MM-YYYY")}
            name="nomineeDOB"
            size={2.5}
          />
          <InputField
            label="Nominee Mobile No"
            value={customerDataNominee?.nomineeMobNo}
            name="nomineeMobileNo"
            size={3.5}
          />
          <InputField
            label="AADHAR Card Number"
            value={customerDataNominee?.nomineeAADHAR}
            name="nomineeAADHAR"
            size={3.5}
          />
        </Grid>
      </fieldset>
      {/* Bank Details Section */}
      <fieldset
        style={{
          marginTop: "10px",
          border: "1px solid #c3c3c3",
          padding: "10px",
        }}
      >
        <legend>Bank Details</legend>
        <Grid container>
          <InputField
            label="Bank Name"
            value={customerDataBankDetail?.bankName}
            name="bankName"
          />
          <InputField
            label="IFSC / SWIFT Code"
            value={customerDataBankDetail?.ifsc}
            name="bankIFSC"
          />
          <InputField
            label="Account Number"
            value={customerDataBankDetail?.accountNumber}
            name="bankAcNo"
          />
          <InputField
            label="Account Type"
            value={customerDataBankDetail?.accountType}
            name="bankAcType"
          />
          <InputField
            label="Branch"
            value={customerDataBankDetail?.bankBranch}
            name="bankBranch"
          />
          <InputField
            label="Account Holder Name"
            value={customerDataBankDetail?.acHolderName}
            name="bankAcHolderName"
          />
        </Grid>
      </fieldset>

      {/* New page break */}
      <div className="page-break"></div>

      {/* Declaration Form Code */}
      <div className="declaration-form">
        <h2>Declaration :</h2>
        <p>
          "I/We, <span className="input-line"></span> &
          <span className="input-line"></span> &
          <span className="input-line"></span>, hereby declare that the
          particulars furnished above are true and correct to the best of my/our
          knowledge and that no information has been withheld/suppressed. I/We
          understand that the sanction of the loan is at the sole discretion of{" "}
          {companyData?.companyName} upon fulfillment of the other terms and
          conditions as required by {companyData?.companyName}. I/We agree that{" "}
          {companyData?.companyName}
          shall be encompNamed to reject my application without giving any
          reason whatsoever. I/We confirm that the loan shall be used for the
          stated purpose and will not be used for any speculative or anti-social
          purpose. I/We declare that I/we will not take any loan from any other
          bank for the purpose as stated in the application form without
          permission from {companyData?.companyName}. I/We authorize{" "}
          {companyData?.companyName} to conduct such credit checks, references,
          and make inquiries in respect of the application as it may consider
          necessary in its sole discretion and also authorize{" "}
          {companyData?.companyName} without any notice to me/us to share and
          disclose the information relating to the loan to any credit bureau,
          financial institution, or any other authority for proper verification
          and other administrative services. I/We undertake to inform{" "}
          {companyData?.companyName} regarding any change in my/our
          occupation/postal address/telephone number and to provide any further
          information that may be required. I/We hereby give my/our consent to
          {companyData?.companyName} to carry out my/our Aadhaar EKYC
          authentication and to fetch EKYC data from Aadhaar database. I/We
          confirm that {companyData?.companyName} may call for additional
          documents other than those already submitted along with the
          application form. I/We confirm that I/we have read the terms and
          conditions applicable to this loan and/or they have been read to me/us
          in <span className="input-line"></span> (vernacular language) by
          <span className="input-line"></span> Mr./Ms.
          <span className="input-line"></span> son/daughter of
          <span className="input-line"></span> and I/we understand, accept, and
          acknowledge the contents herein, and hereby accept the terms and
          conditions herein, and hereby accept the terms and conditions herein.
          I/We agree that these terms and conditions may be changed by{" "}
          {companyData?.companyName} at any time. {companyData?.companyName}{" "}
          reserves the right to retain the photograph and the documents
          submitted with the application and may not return the same to the
          applicant. I/We acknowledge that the information in the loan
          application shall form the basis of any loan{" "}
          {companyData?.companyName} may decide to grant to me/us.{" "}
          {companyData?.companyName}
          may use the services of agents in sales/marketing/service etc. and the
          promotional/service/transactional calls/messages may be sent by such
          agents on behalf of {companyData?.companyName}.
        </p>
        <div className="agreement">
          <label>Agree: </label> <span className="input-line"></span>
        </div>
      </div>

      {/* New page break */}
      <div className="page-break"></div>

      {/* Sign Code */}
      <div className="loan-application-form">
        <div className="form-section">
          <Grid container>
            <Grid item xs={12} sm={6} md={6} paddingX={4}>
              <div className="photo-box">
                <div className="photo-placeholder">
                  <p>
                    Paste photo here and sign across such that your signature is
                    on the photo as well as on the form
                  </p>
                </div>
                <div className="input-group">
                  <label>Name :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
                <div className="input-group">
                  <label>Signature :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
                <div className="input-group">
                  <label>Date :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingX={4}>
              <div className="photo-box">
                <div className="photo-placeholder">
                  <p>
                    Paste photo here and sign across such that your signature is
                    on the photo as well as on the form
                  </p>
                </div>
                <div className="input-group">
                  <label>Name :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
                <div className="input-group">
                  <label>Signature :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
                <div className="input-group">
                  <label>Date :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="form-section">
          <p>
            I/We hereby certify that I/We have done due diligence on the
            information given above and certify that the same is correct. I/We
            have also satisfied myself/ourselves that the customer intends to
            use the loan for the stated purpose only.
          </p>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} paddingX={2}>
              <div className="input-group">
                <label>Referred by :</label>
                <input
                  type="text"
                  value={""}
                  disabled={true}
                  className="underline-input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingX={2}>
              <div className="input-group">
                <label>Referred Id :</label>
                <input
                  type="text"
                  value={""}
                  disabled={true}
                  className="underline-input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingX={2}>
              <div className="input-group">
                <label>Referred Name :</label>
                <input
                  type="text"
                  value={""}
                  disabled={true}
                  className="underline-input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingX={2}>
              <div className="input-group">
                <label>Relationship Officer :</label>
                <input
                  type="text"
                  value={""}
                  disabled={true}
                  className="underline-input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingX={2}>
              <div className="input-group">
                <label>Cluster Manager :</label>
                <input
                  type="text"
                  value={""}
                  disabled={true}
                  className="underline-input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingX={2}>
              <div className="input-group">
                <label>Credit Officer :</label>
                <input
                  type="text"
                  value={""}
                  disabled={true}
                  className="underline-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="acknowledgement-section">
          <Stack
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={
                localStorage.getItem("loggedinUserCompany") == "1365"
                  ? "https://media.licdn.com/dms/image/v2/D4E03AQEYHVC65N8MyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707325481532?e=2147483647&v=beta&t=fnBgoLCTop9Pd1IJQfzhVnknSIrgixpvhvkEmfBOhpo"
                  : `${ImageBaseUrl}${companyData?.profilePic}`
              }
              alt="LOGO"
              width={60}
              height={60}
            />
            <strong style={{ fontSize: "25px" }}>
              Acknowledgement of Loan Application
            </strong>
          </Stack>

          <div style={{ textAlign: "right", marginBlock: "15px" }}>
            Loan Application Form No.: __________
          </div>

          <div style={{ marginBlock: "10px" }}>
            <strong>{companyData?.companyName} </strong>acknowledges the receipt
            of loan application from <span className="input-line"></span> <br />
          </div>

          <Grid container>
            <Grid item xs={12} sm={4.5} md={4.5} paddingX={1}>
              <div className="photo-box">
                <strong>{companyData?.companyName}</strong>
                <p>Registerd Office</p>
                <p>{getAddress(companyData)}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={7.5} md={7.5} paddingX={1}>
              <div className="photo-box">
                <div className="input-group">
                  <label>Name of Relations officer :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
                <div className="input-group">
                  <label>Signature :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
                <div className="input-group">
                  <label>Date :</label>
                  <input
                    type="text"
                    value={""}
                    disabled={true}
                    className="underline-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

LoanApplication.propTypes = {
  data: PropTypes.any,
  printRef: PropTypes.any,
};
