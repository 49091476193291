import "../deleteUser modal/deleteUser.scss";
import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Autocomplete,
  Box,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import ls from "local-storage";
import { BaseUrl } from "../../../Environment";
import warningFill from "../../../assets/images/warningfill.svg";

const axios = require("axios");

class DeleteUser extends Component {
  constructor() {
    super();
    this.state = {
      modal2: false,
      modal3: false,
      caDatas: [],
      errorMsg: "",
      collectorId: "",
    };
    this.closeprofilemodal = this.closeprofilemodal.bind(this);
  }

  closeprofilemodal() {
    this.props.close();
  }

  savechanges() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };

    axios
      .delete(
        BaseUrl + "/user/deleteUser?userId=" + this.props.rowData.userId,
        {
          headers: headers,
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          toast.success("User deleted successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          this.closeprofilemodal();
          this.props.allUser();
        }
      })
      .catch((err) => {
        if (err.response?.data?.status === false) {
          this.setState({
            errorMsg:
              "User cannot be deleted.Would you like to reassign all the loans to an other collection agent?",
            modal2: true,
          });
        } else {
          toast.error("Something went wrong! Please try again.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      });
  }

  updateCollectionAgentloans() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };

    const payload = {
      userId: this.props.rowData.userId,
      collectorId: this.state.collectorId,
    };

    axios
      .put(BaseUrl + "/loan/updateCollectionAgentloans", payload, {
        headers: headers,
      })
      .then((resp) => {
        if (resp.status === 200) {
          this.savechanges();
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  cancelchanges() {
    this.setState({
      modal2: false,
      modal3: false,
      caDatas: [],
      errorMsg: "",
    });
    this.props.close();
  }

  getAllCA = async () => {
    let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
    const headers = {
      Authorization: "Bearer " + ls.get("token"),
    };

    axios
      .get(
        `${BaseUrl}/user/getAllUsers?userName=&companyId=${loggedinUser?.companyId}`,
        {
          headers,
        }
      )
      .then((response) => {
        this.setState({ caDatas: response.data?.result });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  componentDidMount() {
    this.getAllCA();
  }

  render() {
    toast.configure({});
    const { t } = this.props;
    const { modal2, modal3, caDatas, errorMsg, collectorId } = this.state;

    console.log({ collectorId });

    return (
      <div className="Deleteuserpage">
        <Dialog open={this.props.open} style={{ borderRadius: "5px" }}>
          {modal3 ? (
            <div>
              <DialogTitle
                id="customize-dialog-title"
                className="TitleuserDiv"
                style={{ width: "307px", height: "auto" }}
              >
                <div className="deleteuserconfirmation">
                  <h6
                    className="Fonts UserName"
                    style={{
                      textTransform: "capitalize",
                      color: "#3E4664",
                      margin: "0px",
                      fontSize: "20px !important",
                    }}
                  >
                    Please Note:
                  </h6>
                  <ol
                    className="Fonts btnSizeFont confrmusermessage"
                    style={{ margin: "5px" }}
                    type="1"
                  >
                    <li>Deletion of Collection agent cannot be reverted.</li>
                    <li>
                      Loan Collections done by this Collection Agent so far will
                      remain in the Reports.
                    </li>
                    <li>
                      Another option to delete,you can edit and update the phone
                      number and emailId of the new collection agent at the
                      place of old collection agent.
                    </li>
                  </ol>
                  <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                    <Autocomplete
                      id=""
                      fullWidth
                      options={caDatas}
                      autoHighlight
                      // value={}
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName
                      }
                      onChange={(event, newValue) => {
                        this.setState({ collectorId: newValue?.userId });
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.firstName + " " + option.lastName}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a collection agent"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                className="bottomPart"
                style={{ textAlign: "end" }}
              >
                <div>
                  <Button
                    className="cancelbutton Fonts"
                    style={{
                      border: "1px solid #E4E4E4",
                      textTransform: "capitalize",
                      color: "#8E8E98",
                      height: "30px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "2px",
                      margin: "3%",
                    }}
                    onClick={this.cancelchanges.bind(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="savebutton Fonts"
                    style={{
                      backgroundColor: "#00D95E",
                      textTransform: "capitalize",
                      color: "#fff",
                      height: "30px",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      paddingTop: "2px",
                    }}
                    onClick={() => {
                      if (this.state.collectorId) {
                        this.updateCollectionAgentloans();
                      }
                    }}
                  >
                    Update
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : modal2 ? (
            <div>
              <DialogTitle
                id="customize-dialog-title"
                className="TitleuserDiv"
                style={{ width: "307px", height: "113px" }}
              >
                <div className="deleteuserconfirmation">
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <img src={warningFill} alt="Warning" />
                    <p
                      className="Fonts btnSizeFont confrmusermessage"
                      style={{ margin: 0, padding: 0 }}
                    >
                      {errorMsg}
                    </p>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                className="bottomPart"
                style={{ textAlign: "end", marginTop: 10 }}
              >
                <div>
                  <Button
                    className="cancelbutton Fonts"
                    style={{
                      border: "1px solid #E4E4E4",
                      textTransform: "capitalize",
                      color: "#8E8E98",
                      height: "30px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "2px",
                      margin: "3%",
                    }}
                    onClick={this.cancelchanges.bind(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="savebutton Fonts"
                    style={{
                      backgroundColor: "#00D95E",
                      textTransform: "capitalize",
                      color: "#fff",
                      height: "30px",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      paddingTop: "2px",
                    }}
                    onClick={() => {
                      this.setState({
                        modal2: false,
                        modal3: true,
                      });
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div>
              <DialogTitle
                id="customize-dialog-title"
                className="TitleuserDiv"
                style={{ width: "307px", height: "113px" }}
              >
                <div className="deleteuserconfirmation">
                  <p
                    className="Fonts btnSizeFont confrmusermessage"
                    style={{ marginTop: "20px", marginBottom: "5px" }}
                  >
                    {t("DeleteUser.content")}
                  </p>
                  {this.props.rowData && (
                    <h6
                      className="Fonts UserName"
                      style={{
                        textTransform: "capitalize",
                        color: "#3E4664",
                        margin: "0px",
                        fontSize: " 17px !important",
                      }}
                    >
                      {this.props.rowData.firstName}{" "}
                      {this.props.rowData.lastName}
                    </h6>
                  )}
                </div>
              </DialogTitle>
              <DialogContent
                className="bottomPart"
                style={{ textAlign: "end" }}
              >
                <div>
                  <Button
                    className="cancelbutton Fonts"
                    style={{
                      border: "1px solid #E4E4E4",
                      textTransform: "capitalize",
                      color: "#8E8E98",
                      height: "30px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "2px",
                      margin: "3%",
                    }}
                    onClick={this.cancelchanges.bind(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="savebutton Fonts"
                    style={{
                      backgroundColor: "#00D95E",
                      textTransform: "capitalize",
                      color: "#fff",
                      height: "30px",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      paddingTop: "2px",
                    }}
                    onClick={this.savechanges.bind(this)}
                  >
                    Delete
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withNamespaces()(withRouter(DeleteUser));
