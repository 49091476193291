import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(
  "pk_live_51LH80DA4sZHccDHEMdP9c5XwpX8jVMQbwPLNSpsKCQgx6vb3t9f7q6Kbo7DuBGWySpNsP30eYQEDvpElhqo9Savr008QAkWAmY"
);

const PaymentSubscription = (data) => {
  const priceData = JSON.parse(localStorage.getItem("pricingData"));
  const { modalValue } = data;
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm modalValue={modalValue} priceData={priceData} />
    </Elements>
  );
};

export default PaymentSubscription;
