import React from "react";
import "./DemandPromissoryNote.css";
import { Grid, Stack } from "@mui/material";
import Proptypes from "prop-types";
import { ImageBaseUrl } from "../../Environment";

export const DemandPromissoryNote = ({ data, printRef }) => {
  const companyData = data?.company;
  const loanData = data?.loans[0];
  const addressData = data?.addresses[0];

  const { firstName, lastName, fatherName } = data;

  const getAddress = (address) =>
    [
      address?.AddressStreet,
      address?.AddressLandmark,
      address?.AddressCity,
      address?.AddressState,
      address?.AddressPincode,
    ]
      .filter(Boolean)
      .join(", ");

  return (
    <div ref={printRef} className="demand-promissory-note">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black" }}
        >
          <div className="header-left">
            {companyData?.profilePic ? (
              <img
                src={
                  localStorage.getItem("loggedinUserCompany") == "1365"
                    ? "https://media.licdn.com/dms/image/v2/D4E03AQEYHVC65N8MyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707325481532?e=2147483647&v=beta&t=fnBgoLCTop9Pd1IJQfzhVnknSIrgixpvhvkEmfBOhpo"
                    : `${ImageBaseUrl}${companyData?.profilePic}`
                }
                alt="Logo"
                className="logo"
              />
            ) : (
              <div
                style={{
                  height: "120px",
                }}
              ></div>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          paddingX={2}
          style={{ border: "1px solid black", borderRightWidth: "0px" }}
        >
          <div className="header-right">
            <h1>{companyData?.companyName}</h1>
            <p>
              <strong>Address: </strong> {getAddress(companyData)}
              <br />
              <strong>GSTIN: </strong> {companyData?.Gstin} |{" "}
              <strong>Website: </strong> {companyData?.companyWebsite}
              <br />
              <strong>Mobile No: </strong> {companyData?.phone}{" "}
              <strong>| Email ID: </strong> {companyData?.companyMail}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black", borderLeftWidth: "0px" }}
        />
      </Grid>
      <h2 className="title">Demand Promissory Note</h2>

      <Stack spacing={5} marginTop={6}>
        <div>
          <strong>राशि: ₹{loanData?.loanAmount}</strong>
          <br /> मैं श्री/श्रीमती{" "}
          <strong>
            {firstName} {lastName} c/o {fatherName}
          </strong>{" "}
          निवासी{" "}
          <strong>
            {addressData?.AddressStreet} {addressData?.AddressLandmark}
          </strong>
          , जिला <strong>{addressData?.AddressCity}</strong>, राज्य{" "}
          <strong>
            {addressData?.AddressState}, {addressData?.AddressPincode}
          </strong>{" "}
          का मूल निवासी हूँ। {companyData?.companyName} द्वारा मांगे जाने पर
          मेरे द्वारा प्राप्त की गई ऋण राशि ₹{loanData?.loanAmount} का मैं अपने
          ऋण चुकौती की अनुसूची के अनुसार EMI किस्तों का भुगतान करने का वादा
          करता/करती हूँ, जिसे पहले ऋण आवेदन पत्र में मेरे द्वारा जांचा और
          हस्ताक्षरित किया गया था।
        </div>
        <div>
          <strong>Amount: ₹{loanData?.loanAmount}</strong>
          <br /> This is to certify that I,{" "}
          <strong>
            {firstName} {lastName} c/o {fatherName}
          </strong>
          , resident at{" "}
          <strong>
            {addressData?.AddressStreet} {addressData?.AddressLandmark}
          </strong>
          , District - <strong>{addressData?.AddressCity}</strong>, State -{" "}
          <strong>
            {addressData?.AddressState}, {addressData?.AddressPincode}
          </strong>
          , have taken a loan of ₹{loanData?.loanAmount} from{" "}
          {companyData?.companyName}. I hereby promise to pay the EMI
          installments as per the schedule of my loan repayment that was
          previously checked and signed by me in the Loan Application Form.
        </div>
        <h3>Date: </h3>
      </Stack>

      <div
        style={{ display: "flex", justifyContent: "flex-end", marginBlock: 80 }}
      >
        <span
          style={{
            width: 100,
            height: 120,
            border: "1px solid black",
            textAlign: "center",
          }}
        >
          STAMP
        </span>
      </div>

      <div className="footer section">
        <table
          className="footer-table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                <strong>
                  Member Signature
                  <br />
                  (सदस्या के हस्ताक्षर)
                </strong>
                <br />
                <span style={{ marginTop: 30 }} className="input-line"></span>
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                <strong>
                  Spouse Signature
                  <br />
                  (जीवनसाथी के हस्ताक्षर)
                </strong>
                <br />
                <span style={{ marginTop: 30 }} className="input-line"></span>
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                <strong>
                  Guarantor Signature
                  <br />
                  (जमानतदार के हस्ताक्षर)
                </strong>
                <br />
                <span style={{ marginTop: 30 }} className="input-line"></span>
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                <strong>
                  Branch Seal With Signature
                  <br />
                  (शाखा मोहर के साथ हस्ताक्षर)
                </strong>
                <br />
                <span style={{ marginTop: 30 }} className="input-line"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

DemandPromissoryNote.prototype = {
  data: Proptypes.any,
  printRef: Proptypes.any,
};
