import React from "react";
import "../LoanAgreement/LoanAgreement.css";
import { Grid } from "@mui/material";
import Proptypes from "prop-types";
import { ImageBaseUrl } from "../../Environment";
import moment from "moment";

export const EmiCard = ({ data, printRef }) => {
  const companyData = data?.company;
  const customerData = data?.customer;
  const customerDataAddresses = data?.customer?.addresses[0];
  const customerDataBankDetail = data?.customer?.bankDetail;
  const customerDataCoApplicant = data?.customer?.coApplicant;
  const customerDataNominee = data?.customer?.nominee;
  const customerDataGuarantor = data?.customer?.guarantor;
  const customerDataLoanType = data?.loanType;
  const loanCollections = data?.loanCollections;
  const firstLoanCollection = data?.loanCollections[0];

  const {
    loanTenureType,
    loanTenure,
    collectionAmount,
    loanAmount,
    loanId,
    method,
    processFee,
  } = data;

  const getAddress = (address) =>
    [
      address?.AddressStreet,
      address?.AddressLandmark,
      address?.AddressCity,
      address?.AddressState,
      address?.AddressPincode,
    ]
      .filter(Boolean)
      .join(", ");

  const customerAddress = (address) =>
    [address?.AddressStreet, address?.AddressLandmark]
      .filter(Boolean)
      .join(", ");

  const customerAddress2 = (address) =>
    [address?.AddressCity, address?.AddressState, address?.AddressPincode]
      .filter(Boolean)
      .join(", ");
  return (
    <div ref={printRef} className="loan-agreement">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black" }}
        >
          <div className="header-left">
            {companyData?.profilePic ? (
              <img
                src={
                  localStorage.getItem("loggedinUserCompany") == "1365"
                    ? "https://media.licdn.com/dms/image/v2/D4E03AQEYHVC65N8MyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707325481532?e=2147483647&v=beta&t=fnBgoLCTop9Pd1IJQfzhVnknSIrgixpvhvkEmfBOhpo"
                    : `${ImageBaseUrl}${companyData?.profilePic}`
                }
                alt="Logo"
                className="logo"
              />
            ) : (
              <div
                style={{
                  height: "120px",
                }}
              ></div>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          paddingX={2}
          style={{ border: "1px solid black", borderRightWidth: "0px" }}
        >
          <div className="header-right">
            <h1>{companyData?.companyName}</h1>
            <p>
              <strong>Address: </strong> {getAddress(companyData)}
              <br />
              <strong>GSTIN: </strong> {companyData?.Gstin} |{" "}
              <strong>Website: </strong> {companyData?.companyWebsite}
              <br />
              <strong>Mobile No: </strong> {companyData?.phone}{" "}
              <strong>| Email ID: </strong> {companyData?.companyMail}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          paddingX={2}
          style={{ border: "1px solid black", borderLeftWidth: "0px" }}
        />
      </Grid>
      <h2 className="title">EMI Card</h2>
      <div className="content">
        <div className="section">
          <table className="info-table">
            <tbody>
              <tr>
                <td colspan={2}>
                  <strong>Branch : </strong> <br />{" "}
                  <strong>Branch Center : </strong>
                </td>
                <td colspan={2}>
                  <strong>Date : </strong>
                  {moment().format("DD-MM-YYYY")} |{" "}
                  <strong>First EMI Date : </strong>
                  {moment(firstLoanCollection?.dueDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Customer Name</strong> <br />{" "}
                  {customerData?.firstName + ` (${customerData?.cust_id})`}
                </td>
                <td>
                  <strong>Address</strong> <br />{" "}
                  {customerAddress(customerDataAddresses)}
                </td>
                <td>
                  <strong>District/State/Pincode</strong> <br />{" "}
                  {customerAddress2(customerDataAddresses)}
                </td>
                <td rowSpan={3}>
                  <div className="imgBox" />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Contact No</strong> <br /> {customerData?.phone}
                </td>
                <td>
                  <strong>Gender : </strong> {customerData?.gender}
                  <br /> <strong>DOB : </strong>
                  {moment(customerData?.dob).format("DD-MM-YYYY")}
                </td>
                <td>
                  <strong>C/O</strong> <br /> {customerData?.fatherName}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Co-Applicant : </strong> <br />{" "}
                  {customerDataCoApplicant?.coApplicantName}
                </td>
                <td>
                  <strong>Co-Applicant Address : </strong> <br />{" "}
                  {customerDataCoApplicant?.coApplicantAddress}
                </td>
                <td>
                  <strong>Co-Applicant Phone : </strong> <br />{" "}
                  {customerDataCoApplicant?.coApplicantMobNo}
                </td>
              </tr>
              <tr>
                <td colspan={2}>
                  <h3>Bank Details</h3>
                </td>
                <td colspan={2}>
                  <h3>Applied Loan Details</h3>
                </td>
              </tr>

              <tr>
                <td>Bank : {customerDataBankDetail?.bankName}</td>
                <td>Branch : {customerDataBankDetail?.bankBranch}</td>
                <td>Loan ID : {loanId}</td>
                <td>Processing Fee : {processFee}</td>
              </tr>
              <tr>
                <td>
                  Account Number : {customerDataBankDetail?.accountNumber}
                </td>
                <td>Account Type : {customerDataBankDetail?.accountType}</td>
                <td colSpan={2}>
                  Amount to be Paid :{" "}
                  {parseFloat(collectionAmount) * parseFloat(loanTenure)}
                </td>
              </tr>
              <tr>
                <td>Account Holder : {customerDataBankDetail?.acHolderName}</td>
                <td>IFSC : {customerDataBankDetail?.ifsc}</td>
                <td colSpan={2}>Loan Amount : {loanAmount}</td>
              </tr>
              <tr>
                <td colspan={2}>
                  <h3>Income Details</h3>
                </td>
                <td>Loan Type : {customerDataLoanType?.loanType}</td>
                <td>Interest Amt : {collectionAmount}</td>
              </tr>

              <tr>
                <td>Income (INR) : {customerData?.annualIncome}</td>
                <td>Occupation : {customerData?.incomeSource}</td>
                <td>Recovery type : {loanTenureType}</td>
                <td>No of Payments : {loanTenure}</td>
              </tr>
              <tr>
                <td colspan={3}>
                  <h3>Guarantor Details</h3>
                </td>
                <td rowSpan={3}>
                  <div className="imgBox" />
                </td>
              </tr>
              <tr>
                <td>Name : {customerDataGuarantor?.guarantorName}</td>
                <td>Address : {customerDataGuarantor?.guarantorAddress}</td>
                <td>
                  Contact Number : {customerDataGuarantor?.guarantorMobNo}
                </td>
              </tr>
              <tr>
                <td>Nominee Name : {customerDataNominee?.nomineeName}</td>
                <td>Nominee Mobile : {customerDataNominee?.nomineeMobNo}</td>
                <td>
                  Nominee DOB :{" "}
                  {moment(customerDataNominee?.nomineeDOB).format("DD-MM-YYYY")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="footer section">
          <table
            className="footer-table"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <tbody>
              <tr>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Member Signature
                    <br />
                    (सदस्या के हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Spouse Signature
                    <br />
                    (जीवनसाथी के हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Guarantor Signature
                    <br />
                    (जमानतदार के हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  <strong>
                    Branch Seal With Signature
                    <br />
                    (शाखा मोहर के साथ हस्ताक्षर)
                  </strong>
                  <br />
                  <span style={{ marginTop: 30 }} className="input-line"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* New page break */}
        <div className="page-break"></div>

        <div className="section">
          <h2 className="title">Repayment Schedule Terms</h2>

          <table className="info-table">
            <thead>
              <th>Sr. No</th>
              <th>EMI Date</th>
              {method == "Collection method" ? (
                <>
                  <th>Opening Balance</th>
                  <th>Closing Balance</th>
                </>
              ) : (
                <>
                  <th>Opening Balance</th>
                  <th>Closing Balance</th>
                  <th>Interest</th>
                  <th>Principal</th>
                </>
              )}
              <th>Installment</th>
              <th>Signature</th>
            </thead>
            <tbody>
              {loanCollections?.map((data, i) => {
                const totalRows = loanCollections.length;
                const maxPageBreaks = Math.floor(totalRows / 25); // Calculate the maximum number of page breaks
                return (
                  <React.Fragment key={data?.srNo}>
                    <tr>
                      <td style={{ width: "10%" }}>{i + 1}</td>
                      <td style={{ width: "20%" }}>
                        {moment(data?.dueDate).format("DD-MM-YYYY")}
                      </td>
                      {method == "Collection method" ? (
                        <>
                          <td style={{ width: "20%" }}>
                            {data?.openingBalance}
                          </td>
                          <td style={{ width: "20%" }}>
                            {data?.closingBalance}
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ width: "10%" }}>
                            {data?.openingBalance}
                          </td>
                          <td style={{ width: "10%" }}>
                            {data?.closingBalance}
                          </td>
                          <td style={{ width: "10%" }}>{data?.interest}</td>
                          <td style={{ width: "10%" }}>{data?.principal}</td>
                        </>
                      )}
                      <td style={{ width: "20%" }}>{data?.Installment}</td>
                      <td style={{ width: "10%" }}></td>
                    </tr>
                    {(i + 1) % 25 === 0 &&
                      (i + 1) / 25 <= maxPageBreaks &&
                      i + 1 < totalRows && <tr className="page-break"></tr>}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

EmiCard.prototype = {
  data: Proptypes.any,
  printRef: Proptypes.any,
};
