import Axios from "axios";
import { BaseUrl } from "./Environment";

const currencyArray = [
  { code: "AUD", symbol: "$" },
  { code: "BHD", symbol: ".د.ب‎" },
  { code: "BDT", symbol: "৳" },
  { code: "BWP", symbol: "P" },
  { code: "BRL", symbol: "R$" },
  { code: "KHR", symbol: "៛" },
  { code: "COP", symbol: "$" },
  { code: "EGP", symbol: "£" },
  { code: "GHS", symbol: "¢" },
  { code: "HKD", symbol: "$" },
  { code: "INR", symbol: "₹" },
  { code: "IDR", symbol: "Rp" },
  { code: "JMD", symbol: "J$" },
  { code: "KES", symbol: "KSh" },
  { code: "KWD", symbol: "د.ك " },
  { code: "MYR", symbol: "RM" },
  { code: "MUR", symbol: "₨" },
  { code: "MXN", symbol: "$" },
  { code: "NAD", symbol: "$" },
  { code: "NZD", symbol: "$" },
  { code: "NGN", symbol: "₦" },
  { code: "OMR", symbol: "﷼" },
  { code: "PKR", symbol: "₨" },
  { code: "PHP", symbol: "₱" },
  { code: "QAR", symbol: "﷼" },
  { code: "RWF", symbol: "R₣" },
  { code: "SAR", symbol: "﷼" },
  { code: "SGD", symbol: "$" },
  { code: "ZAR", symbol: "R" },
  { code: "LKR", symbol: "₨" },
  { code: "TZS", symbol: "TSh" },
  { code: "THB", symbol: "฿" },
  { code: "AED", symbol: " د.إ" },
  { code: "VND", symbol: "₫" },
];

export function getCurrencySymbol(code) {
  for (let i = 0; i < currencyArray.length; i++) {
    if (currencyArray[i].code == code) {
      return currencyArray[i].symbol;
    }
  }
  return "Code not found";
}

export function trialDaysRemaining(startDateStr) {
  const trialPeriodDays = 14;
  // Convert the start date string to a Date object
  const startDate = new Date(startDateStr);
  
  // Get the current date
  const currentDate = new Date();
  
  // Calculate the end date of the trial period
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + trialPeriodDays);
  
  // Calculate the remaining days
  const remainingTime = +endDate - +currentDate;
  const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  
  // Ensure the remaining days are not negative
  return Math.max(remainingDays, 0);
}

export const getUserTypeDetails = async (userType, companyId,userId) => {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    const headers = {
      Authorization: "Bearer " + token,
    };

    const result = await Axios.get(
      `${BaseUrl}/userType/getUserTypeDetails?userType=${userType}&companyId=${companyId}&userId=${userId}`,
      {
        headers,
      }
    );

    localStorage.setItem(
      "UsersAccess",
      JSON.stringify(
        Object.fromEntries(
          Object.entries(result.data).filter(
            ([key, value]) => typeof value === "boolean"
          )
        )
      )
    );
  } catch (error) {
    console.log("error: " + JSON.stringify(error));
  }
};

