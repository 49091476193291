import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import timeReport from "../../../assets/images/time_report.svg";
import loanReport from "../../../assets/images/loan_report.svg";
import userReport from "../../../assets/images/user_report.svg";
import Grid from "@material-ui/core/Grid";
import "../ReportModal/ReportModal.scss";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProfileModal = ({ open, close }) => {
  const history = useHistory();

  const closeProfileModal = () => {
    close();
  };

  const dayWiseReport = () => {
    if (history.location.pathname !== '/collectionreport') {
      history.push('/collectionreport');
    } else {
      history.replace('/');
      setTimeout(() => {
        history.push('/collectionreport');
      }, 10);
    }
  };

  const customerWiseReport = () => {
    if (history.location.pathname !== '/customerwisereport') {
      history.push('/customerwisereport');
    } else {
      history.replace('/');
      setTimeout(() => {
        history.push('/customerwisereport');
      }, 10);
    }
  };

  const loanWiseReport = () => {
    if (history.location.pathname !== '/loanwisereport') {
      history.push('/loanwisereport');
    } else {
      history.replace('/');
      setTimeout(() => {
        history.push('/loanwisereport');
      }, 10);
    }
  };

  return (
    <Dialog open={open} className="mainDiv">
      <DialogTitle id="customized-dialog-title" className="TitleDiv">
        <div style={{ display: "flex" }}>
          <Grid item xs={4}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeProfileModal}
            />
          </Grid>
        </div>
      </DialogTitle>
      <DialogContent className="bottomPart">
        <div>
          <div onClick={dayWiseReport} className="usermanagement Fonts">
            <img src={timeReport} alt="buildingImage" />
            <h6 className="usermanagementtext">Day-Wise Collection Report</h6>
          </div>
          <div onClick={customerWiseReport} className="usermanagement Fonts">
            <img src={userReport} alt="buildingImage" />
            <h6 className="usermanagementtext">
              Customer-Wise Collection Report
            </h6>
          </div>
          <div onClick={loanWiseReport} className="usermanagement Fonts">
            <img src={loanReport} alt="buildingImage" />
            <h6 className="usermanagementtext">Loan-Wise Collection Report</h6>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withNamespaces()(withRouter(ProfileModal));
