import React, { useEffect, useState } from "react";
import {
  Stack,
  Switch,
  Paper,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import Select from "react-select";
import Axios from "axios";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import { BaseUrl } from "../../Environment";

const Settings = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const [userType, setUserType] = useState();
  const [userTypes, setUserTypes] = useState([]);
  const [userTypeDetail, setUserTypeDetail] = useState({});
  const [initialUserTypeDetail, setInitialUserTypeDetail] = useState({});
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [automaticApproveDisburse, setAutomaticApproveDisburse] =
    useState(false);

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(
    loggedinUser?.userType === "superAdmin" ? "1" : "2"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle2 = () => {
    setAutomaticApproveDisburse(!automaticApproveDisburse);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  // Step 1: Initialize state with your array of options mapped to API keys
  const [options, setOptions] = useState([
    { label: "Add/Edit Borrowers", key: "addBorrowers", value: false },
    { label: "View Borrowers", key: "viewBorrowers", value: false },
    { label: "Add/Edit Loan", key: "addLoan", value: false },
    { label: "Add/Edit Loan Types", key: "addLoanTypes", value: false },
    { label: "View Loans", key: "viewLoan", value: false },
    { label: "Approve/Disburse Loan", key: "approveLoan", value: false },
    { label: "Revert Loan Payment", key: "revertPayment", value: false },
    { label: "Payment Collection", key: "receivePayment", value: false },
    { label: "View Reports", key: "viewReports", value: false },
    { label: "Print Documents", key: "printDocs", value: false },
    { label: "Expenses", key: "Expenses", value: false },
    { label: "Accounting", key: "accounting", value: false },
    { label: "Add/Edit User Management", key: "addUsers", value: false },
    { label: "Add/Edit Company Profile", key: "editProfile", value: false },
  ]);

  // Step 2: Function to handle the switch toggle
  const handleToggle = (index) => {
    // Update the userTypeDetail with the new value
    if (options[index].key === "viewBorrowers" && options[index].value) {
      setOptions((prevOptions) =>
        prevOptions.map((option, i) =>
          i === index
            ? { ...option, value: !option.value }
            : i === index - 1
            ? { ...option, value: false }
            : option
        )
      );
      setUserTypeDetail((prevDetails) => ({
        ...prevDetails,
        [options[index].key]: !options[index].value, // Toggle the value for the corresponding key
        [options[index - 1].key]: false,
      }));
    } else if (options[index].key === "viewLoan" && options[index].value) {
      setOptions((prevOptions) =>
        prevOptions.map((option, i) =>
          i === index
            ? { ...option, value: !option.value }
            : i === index - 2
            ? { ...option, value: false }
            : option
        )
      );
      setUserTypeDetail((prevDetails) => ({
        ...prevDetails,
        [options[index].key]: !options[index].value, // Toggle the value for the corresponding key
        [options[index - 2].key]: false,
      }));
    } else {
      setOptions((prevOptions) =>
        prevOptions.map((option, i) =>
          i === index ? { ...option, value: !option.value } : option
        )
      );
      setUserTypeDetail((prevDetails) => ({
        ...prevDetails,
        [options[index].key]: !options[index].value, // Toggle the value for the corresponding key
      }));
    }
  };

  const getAllUserTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.get(
        `${BaseUrl}/userType/getAllUserType?companyId=${loggedinUser.companyId}`,
        {
          headers,
        }
      );

      if (result.data.length > 0) {
        const saData = result?.data?.filter((val) => val?.userType == "superAdmin");
        setUserType(saData[0]?.userTypeId)
        setUserTypes(
          result?.data?.map((item) => ({
            label:
              item?.userType === "superAdmin" ? "Super Admin" : item.userType,
            value: item?.userTypeId,
          }))
        );
      }
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  const getUserTypeDetails = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.get(
        `${BaseUrl}/userType/getUserTypeDetails?userTypeId=${userType}&companyId=${loggedinUser?.companyId}&userId=${loggedinUser?.userId}`,
        {
          headers,
        }
      );

      // Update the userTypeDetail state with the data from the API
      setUserTypeDetail(result.data);
      setInitialUserTypeDetail(result.data);

      const saData = userTypes?.filter((val) => val?.label == "Super Admin");

      if (userType == saData[0]?.value) {
        localStorage.setItem(
          "UsersAccess",
          JSON.stringify(
            Object.fromEntries(
              Object.entries(result.data).filter(
                ([key, value]) => typeof value === "boolean"
              )
            )
          )
        );
      }

      // Initialize the switch states based on the API data
      setOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          value: result.data[option.key] || false, // Initialize value based on API data
        }))
      );
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  // Check if any data has changed when the options or userTypeDetail state changes
  useEffect(() => {
    const isChanged =
      JSON.stringify(userTypeDetail) !== JSON.stringify(initialUserTypeDetail);
    setIsDataChanged(isChanged);
  }, [userTypeDetail, initialUserTypeDetail]);

  useEffect(() => {
    if(userType){
      getUserTypeDetails();
    }
  }, [userType]);

  useEffect(() => {
    getAllUserTypes();
  }, []);

  const updateUserType = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const headers = {
        Authorization: "Bearer " + token,
      };

      const result = await Axios.put(
        `${BaseUrl}/userType/putUserType`,
        userTypeDetail,
        {
          headers,
        }
      );

      if (result.data?.status) {
        setToastMsg({
          msg: `User Access Updated Successfully`,
          time: 4000,
          variant: "success",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);

        setIsDataChanged(false);
        getUserTypeDetails();
      }
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
    }
  };

  return (
    <>
      <Sidebar />

      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"10px 100px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Stack
        className="otherincomepage"
        spacing={0}
        padding={"0px 20px 0px 100px"}
      >
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            {loggedinUser?.userType === "superAdmin" && (
              <Tab label="Loan Settings" value={"1"} />
            )}
            <Tab label="Access Settings" value={"2"} />
          </Tabs>
        </Box>
        {value === "1" ? (
          <Paper
            elevation={3}
            sx={{ p: 2, width: matches ? "85%" : "50%", overflow: "auto" }}
            style={{ margin: "10px auto" }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              paddingInline={2}
            >
              <Stack>
                <strong style={{ fontFamily: "Lato", fontSize: 15 }}>
                  Automatically Approve and Disburse Loans
                </strong>
                <em
                  style={{ fontFamily: "Lato", fontSize: 13, width: "300px" }}
                >
                  {`(when enabled, this will automatically change the loan status to disbursed as soon as the loan is created)`}
                </em>
              </Stack>
              <Switch
                aria-label={"automaticApproveDisburse"}
                checked={automaticApproveDisburse}
                onChange={handleToggle2}
                // disabled={}
              />
            </Stack>
          </Paper>
        ) : (
          <Paper
            elevation={3}
            sx={{ p: 2, width: matches ? "85%" : "50%", overflow: "auto" }}
            style={{ margin: "10px auto" }}
          >
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack spacing={0.5}>
                <strong style={{ fontFamily: "Lato", fontSize: 14 }}>
                  Choose User Type
                </strong>
                <Select
                  options={userTypes}
                  placeholder="Select User's Type"
                  value={userTypes?.find((item) => item.value === userType)}
                  onChange={(val) => setUserType(val?.value)}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "250px",
                      fontFamily: "Lato",
                    }),
                  }}
                />
              </Stack>
            </div>
            {options?.map((data, index) => (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingInline={2}
                key={index}
              >
                <strong style={{ fontFamily: "Lato", fontSize: 15 }}>
                  {data?.label}
                </strong>
                <Switch
                  aria-label={data?.label}
                  checked={data?.value}
                  onChange={() => handleToggle(index)}
                  disabled={
                    // Disable "addBorrowers" if "viewBorrowers" is false
                    (data.key === "addBorrowers" &&
                      !options.find((o) => o.key === "viewBorrowers").value) ||
                    // Disable "addLoan" if "viewLoan" is false
                    (data.key === "addLoan" &&
                      !options.find((o) => o.key === "viewLoan").value)
                  }
                />
              </Stack>
            ))}
          </Paper>
        )}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          style={{ margin: "10px auto", width: matches ? "85%" : "50%" }}
        >
          {value === "1" ? (
            <Button
              className="GreenBTN"
              style={{ width: "100px" }}
              variant="filled"
              onClick={() => {}}
              // disabled={!isDataChanged}
            >
              Save
            </Button>
          ) : (
            <Button
              className="GreenBTN"
              style={{ width: "100px" }}
              variant="filled"
              onClick={updateUserType}
              disabled={!isDataChanged} // Disable if no data change
            >
              Save
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Settings;
