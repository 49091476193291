import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, permissions, requiredPermission, ...rest }) => {
  const userPermissions = JSON.parse(localStorage.getItem("UsersAccess"));

  return (
    <Route
      {...rest}
      render={(props) =>
        userPermissions && userPermissions[requiredPermission] ? (
          <Component {...props} />
        ) : (
          <Redirect to="/notfound" />
        )
      }
    />
  );
};

export default ProtectedRoute;
