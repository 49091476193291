import "../login.scss";
import "react-toastify/dist/ReactToastify.css";

import React, { Component } from "react";
import { Card, Grid } from "@mui/material";
import { toast } from "react-toastify";
import loginimage from "../../../assets/images/loginimage.svg";
import GooglePlay from "../../../assets/images/Group 28315.svg";
import AppStore from "../../../assets/images/Group 28316.svg";
import { BaseUrl } from "../../../Environment";
import { auth, provider } from "../../../firebase/config";
import LoadingButton from "@mui/lab/LoadingButton";
import GoogleIcon from "@mui/icons-material/Google";

const axios = require("axios");
class GoogleSignin extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  signin = () => {
    auth
      .signInWithPopup(provider)
      .then((res) => {
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  componentDidMount() {}

  render() {
    toast.configure({});
    const { loading } = this.state;

    return (
      <Grid container className="loginPage dBlockCss">
        <Grid item md={6} className="paddingLoginGrids">
          <div>
            <div className="firstH3 TextColour">Collect like a PRO!</div>
            <div className="firstH4 TextColour">
              Digital Bharat ka Digital Khata
            </div>
          </div>
          <div>
            <div>
              <img src={loginimage} alt="login" className="loginIMage" />
              <div className="secondH4">
                Digital Ledger Account (Udhar Bahi Khata book) for Credit,
                Lending and Hisab Khitab for small lenders, grocers and
                shopkeepers
              </div>
              <div className="storeButtons">
                <div className="storeButtonsOne">
                  <a href="https://play.google.com/store/apps/details?id=com.lendstack" target="_blank" className="googlePlay">
                    <img
                      src={GooglePlay}
                      alt="googlePlay"
                      className="googlePlay"
                    />
                  </a>
                  <div className="appStore">
                    <img src={AppStore} alt="appStore" className="appStore" />
                  </div>
                </div>
                <div className="clickHere">
                  Read our{" "}
                  <a
                    target="_blank"
                    href="https://lendstack.app/#/termsofservice"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://lendstack.app/#/privacypolicy"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} className="paddingLoginGrids">
          <Card className="CardDivLogin">
            <div className="CardDiv4">
              <div className="CardDiv5 HighlightTextColour textLeft">
                <h4 style={{ margin: 0 }}>Welcome!</h4>
                <div className="firstH4 TextColour textLeft">
                  Sign in using your Google Account
                </div>
              </div>

              <div className="btnDiv" style={{ marginTop: 10 }}>
                <LoadingButton
                  variant="outlined"
                  fullWidth
                  className="Fonts btnSizeFont btn"
                  onClick={this.signin}
                  disabled={loading}
                  loading={loading}
                  startIcon={<GoogleIcon />}
                >
                  Sign in with Google
                </LoadingButton>
              </div>
              <div className="TextColour linkFontSize textLeft">
              By clicking, I accept the Terms of Service and Privacy Policy.
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default GoogleSignin;
