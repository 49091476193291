import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import loginimage from '../../assets/images/loginimage.svg';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import './start.scss';
import { Link } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GooglePlay from '../../assets/images/Group 28315.svg';
import AppStore from '../../assets/images/Group 28316.svg';
import { BaseUrl } from "../../Environment"

const axios = require('axios');
class start extends Component {
    constructor() {
        super();   }   
   
    GotoSignup() {
        this.props.history.push("/signup/register")
    }
    
    Forgetpassword() {
        this.props.history.push("/completemyregistration")
    }

    handleKey = (event) => {
        if (event.key === "Enter") {
            this.handleSubmit(event)
        }
    }

    componentDidMount(){
    }

    render() {
        toast.configure({});
        return (
            <div className="loginPage">
                <div className="mainDiv">
                <div style={{marginTop: "4%", display:'flex'}}>
                <div style={{marginLeft: 'auto'}}>
                    <div className="firstDiv">
                        <div className="firstDivOne">
                            <div className="firstH3 TextColour">Collect like a PRO!</div>
                            <div className="firstH4 TextColour">Digital Bharat ka Digital Khata</div>
                        </div>
                    </div>
                    <div className="secondDiv">
                        <div className="ImageDiv">
                            <img src={loginimage} alt="login" className="loginIMage" />
                            <div className="secondH4">Digital Ledger Account (Udhar Bahi Khata book) for Credit, Lending and Hisab Khitab for small lenders, grocers and shopkeepers</div>
                            <div className="storeButtons">
                        <div className="storeButtonsOne">
                            <div className="googlePlay">
                                <img src={GooglePlay} alt="googlePlay" className="googlePlay" />
                            </div>
                            <div className="appStore">
                                <img src={AppStore} alt="appStore" className="appStore" />
                            </div>
                        </div>
                        <div className="clickHere">
                        Read our <a target="_blank" href='https://lendstack.app/#/termsofservice'>Terms of Service</a> and <a target="_blank" href='https://lendstack.app/#/privacypolicy'>Privacy Policy</a>
                        </div>
                    </div>

                        </div>
                        </div>
                    </div>
                    <div className="CardDiv">
                            <div className="CardDiv2">
                                <Card className="CardDiv3">
                                    <div className="CardDiv4">
                                        <div className="CardDiv5 HighlightTextColour">
                                            <h3 className="Fonts">Before we get started</h3>
                                        </div>
                                            <div className="startH4">
                                            <h4>We are a cloud lending-as-a-service (LaaS) platform that helps small lenders, grocers and shopkeepers digitize their lending businesses.</h4>
                                            <h4 className="Fonts TextColour"><b>We do not give out loans.</b></h4>                                            
                                            <h4 className="Fonts">What best describes you?</h4>
                                        </div>
                                        <div className="btnDiv">
                                            <Button variant="outlined" fullWidth className="Fonts btnSizeFont btn"  target="_blank" href='https://wtfabrikat.com/'>
                                                I WANT A LOAN/I AM A BORROWER
                                            </Button>
                                        </div>
                                        <a className="btnDiv">
                                            <Button variant="outlined" fullWidth className="Fonts btnSizeFont btn" onClick={this.GotoSignup.bind(this)}>
                                                I WANT TO LEND/I AM A LENDER
                                            </Button>
                                        </a>
                                        <div className="linkDiv HighlightTextColour">
                                            <a className="HighlightTextColour Fonts" style={{ textDecoration: 'underline', cursor: "pointer",fontSize: "16px"}} onClick={this.Forgetpassword.bind(this)}>Complete My Registration</a></div>                                  
                                    <div className="linkDiv HighlightTextColour">
                                            <a className="HighlightTextColour Fonts" style={{ textDecoration: 'underline', cursor: "pointer",fontSize: "16px"}} target="_blank" href='https://wtfabrikat.com/product/'>Learn more about our Digital Lending Solutions</a></div>

                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                    </div>{/* 
                    <div className="footer">
            <h1>{"LendStack.app is a property of What To Fabrikat, duly registered with UDAYAM-MH-26-0355899 and operates under the laws of the Republic of India. LendStack.app is NOT a lender, does not make offers for loans, and does not broker online loans to lenders or lending partners. LendStack.app is not a representative or agent of any lender or lending partner. LendStack.app is a lending-as-a-service cloud platform for lenders. These disclosures are intended for informational purposes only and should not be construed as or considered legal advice."}</h1>
          </div> */}                
            </div>            
        )
    }
}
export default start