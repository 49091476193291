export const administratorline = require('./images/administratorline.svg');
export const agricultureicon = require('./images/agricultureicon.svg');
export const backArrowWhite = require('./images/backArrowWhite.svg');
export const backbuttn = require('./images/backbuttn.svg');
export const backimag = require('./images/backimag.svg');
export const buildingline = require('./images/buildingline.svg');
export const businessicon = require('./images/businessicon.svg');
export const calendartime = require('./images/calendartime.svg');
export const cameradefault = require('./images/cameradefault.svg');
export const cardimage = require('./images/cardimage.svg');
export const cardtype = require('./images/cardtype.svg');
export const cardviewgreen = require('./images/cardviewgreen.svg');
export const caricon = require('./images/caricon.svg');
export const check = require('./images/check.svg');
export const companylogo = require('./images/companylogo.svg');
export const creditcardicon = require('./images/creditcardicon.svg');
export const cross = require('./images/cross.svg');
export const crosscancel = require('./images/crosscancel.svg');
export const customercamera = require('./images/customercamera.svg');
export const customerloan = require('./images/customerloan.svg');
export const dashboard = require('./images/dashboard.svg');
export const deleteicon = require('./images/deleteicon.svg');
export const deletewhite = require('./images/deletewhite.svg');
export const deletered = require('./images/deletered.svg');
export const details = require('./images/details.svg');
export const downarrow = require('./images/downarrow-green.svg');
export const editpencil = require('./images/editpencil.svg');
export const editwhite = require('./images/editwhite.svg');
export const educationalicon = require('./images/educationalicon.svg');
export const excel = require('./images/excel.svg');
export const exportcrediticon = require('./images/exportcrediticon.svg');
export const eyeline = require('./images/eyeline.svg');
export const filter = require('./images/filter.svg');
export const greentick = require('./images/greentick.svg');
export const gridtypeactive = require('./images/gridtypeactive.svg');
export const gridview = require('./images/gridview.svg');
export const Group_28315 = require('./images/Group 28315.svg');
export const Group_28316 = require('./images/Group 28316.svg');
export const handset = require('./images/handset.svg');
export const help = require('./images/help.svg');
export const homeicon = require('./images/homeicon.svg');
export const infoimag = require('./images/infoimag.svg');
export const listimage = require('./images/listimage.svg');
export const loan = require('./images/loan.svg');
export const loanagainstfixeddeposit = require('./images/loanagainstfixeddeposit.svg');
export const loanagainstgold = require('./images/loanagainstgold.svg');
export const loginimage = require('./images/loginimage.svg');
export const members = require('./images/members.svg');
export const message = require('./images/message.svg');
export const messanger = require('./images/messanger.svg');
// export const microcrediticon = require('./images/microcrediticon .svg');
export const modalprofile = require('./images/modalprofile.svg');
export const msgadd = require('./images/msgadd.svg');
export const msgimag = require('./images/msgimag.svg');
export const msgsetting = require('./images/msgsetting.svg');
export const newcustomer1 = require('./images/newcustomer1.svg');
export const newcustomer2 = require('./images/newcustomer2.svg');
export const newfield = require('./images/newfield.svg');
export const noun_message_160429 = require('./images/noun_message_160429.svg');
export const Path = require('./images/Path 36741.svg');
export const pdf = require('./images/pdf.svg');
export const pencil = require('./images/pencil-solid.svg');
export const penciledit = require('./images/penciledit.svg');
export const personalicon = require('./images/personalicon.svg');
export const phone = require('./images/phone-handset-solid.svg');
export const plusgreen = require('./images/plusgreen.svg');
export const plusicon = require('./images/plusicon.svg');
export const profile = require('./images/profile.svg');
export const redcross = require('./images/redcross.svg');
export const remove = require('./images/remove.svg');
export const renewableenargyloan = require('./images/renewableenargyloan.svg');
export const searchicon = require('./images/searchicon.svg');
export const share = require('./images/share.svg');
export const sharepageImage = require('./images/sharepageImage.svg');
export const sidebarline = require('./images/sidebarline.svg');
export const tablecus = require('./images/tablecus.svg');
export const twoweelericon = require('./images/twoweelericon.svg');
export const user_solid = require('./images/user-solid .svg');
export const userdefault = require('./images/userdefault.svg');
export const Whatsapp = require('./images/Whatsapp.svg');
export const womenentrepreneuricon = require('./images/womenentrepreneuricon.svg');
export const BlankCheque = require('./images/samplecheque.png');
