import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Divider,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
  Chip,
  Tooltip,
} from "@mui/material";
import { ChevronRight, Info, SupportAgent } from "@mui/icons-material";
import "./pricing.scss";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import PaymentSubscription from "../Payment";
import { Call, Email, WhatsApp } from "@material-ui/icons";
import { getCurrencySymbol, trialDaysRemaining } from "../../utilsFunctions";

const Pricing = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
  let countryCurrency = JSON.parse(localStorage.getItem("companyCountry"));
  // const rates = JSON.parse(localStorage.getItem("conversion_rates"));

  // const convert = (amount) => {
  //   if (rates["INR"] && rates[countryCurrency?.symbol]) {
  //     const rate = rates[countryCurrency?.symbol] / rates["INR"];
  //     return Math.round(amount * rate);
  //   }
  // };

  const [modalValue, setModalValue] = React.useState();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openSupport, setOpenSupport] = React.useState(false);

  const handleClickOpenSupport = () => {
    setOpenSupport(true);
  };

  const handleCloseSupport = () => {
    setOpenSupport(false);
  };

  const trialActive = loggedinUser?.company?.trialActive;
  const trialDays = trialDaysRemaining(loggedinUser?.company?.createdAt);

  const plansData = [
    {
      id: "0",
      name: trialActive ? "Trial" : "Free",
      image: "https://mui.com/static/branding/pricing/x-plan-pro.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(500),
      originalPrice: 500,
      discountedPrice: 0,
      centerBoxText: "Free Forever",
      features: [
        "10 Active Loans",
        "20 Borrowers",
        trialActive ? "3 User" : "1 User",
        trialActive ? "Unlimited Plan's Feature" : "No Support",
        trialActive ? "" : "No Backup",
      ],
    },
    {
      id: "1",
      name: "Starter",
      image: "https://mui.com/static/branding/pricing/x-plan-pro.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(7000),
      // discountedPrice: rates[countryCurrency?.symbol] && convert(4000),
      originalPrice: 7000,
      discountedPrice: 4000,
      centerBoxText: "40% Discount",
      features: [
        "500 Active Loans",
        "1000 Borrowers",
        "3 Users",
        "Limited Customer Support",
        "Manual Backups",
      ],
    },
    {
      id: "2",
      name: "Business Plus",
      image: "https://mui.com/static/branding/pricing/x-plan-community.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(11000),
      // discountedPrice: rates[countryCurrency?.symbol] && convert(7000),
      originalPrice: 11000,
      discountedPrice: 7000,
      centerBoxText: "40% Discount",
      features: [
        "1000 Active Loans",
        "2000 Borrowers",
        "5 Users",
        "Priority Customer Support",
        "Manual Backups",
      ],
    },
    {
      id: "3",
      name: "Unlimited",
      image: "https://mui.com/static/branding/pricing/x-plan-premium.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(18000),
      // discountedPrice: rates[countryCurrency?.symbol] && convert(11000),
      originalPrice: 18000,
      discountedPrice: 11000,
      centerBoxText: "40% Discount",
      features: [
        "Unlimited Active Loans",
        "Unlimited Borrowers",
        "Unlimited Users",
        "24/7 Priority Customer Support",
        "Automatic Backups",
      ],
    },
  ];

  const salientFeaturesData = [
    {
      name: "SALIENT FEATURES",
      img: "https://mui.com/static/branding/product-toolpad-light.svg",
      subData: [
        {
          name: "Capital Account Management",
          data: ["No", "No", "Yes"],
        },
        {
          name: "Generate Loan Agreements",
          data: ["No", "No", "Yes"],
        },
        { name: "Expense Tracker", data: ["No", "Yes", "Yes"] },
      ],
    },
  ];

  const commonPlanData = [
    {
      name: "LOAN MANAGEMENT",
      img: "https://mui.com/static/branding/product-core-light.svg",
      subData: [
        { name: "Loan Product Customization" },
        { name: "Penalty configuration" },
        { name: "Double Entry Accounting System (Cash Ledger)" },
        { name: "Multiple Areas & Lines" },
        { name: "Payment Reversal" },
        { name: "Direct/EMI Method loans (Daily/Weekly/Monthly)" },
      ],
    },
    {
      name: "BORROWER MANAGEMENT",
      img: "https://mui.com/static/branding/product-advanced-light.svg",
      subData: [
        { name: "Know your customers (KYC)" },
        { name: "Digilocker - KYC review and approval" },
        { name: "Free SMS Reminders" },
        { name: "Free SMS/WhatsApp Receipt Generations" },
        { name: "Geo-Mapping of your Borrowers" },
      ],
    },
    {
      name: "PAYMENTS",
      img: "https://mui.com/static/branding/product-templates-light.svg",
      subData: [
        { name: "Cards" },
        { name: "Wallet" },
        { name: "Direct debits" },
        { name: "UPI" },
      ],
    },
    {
      name: "ROSCA SAVINGS (coming soon)",
      img: "https://mui.com/static/branding/product-designkits-light.svg",
      subData: [
        { name: "Chit Fund products" },
        { name: "Investment Products" },
      ],
    },
    {
      name: "SECURITY",
      img: "https://mui.com/static/branding/product-toolpad-light.svg",
      subData: [
        { name: "2 Factor Authentication" },
        { name: "Biometric Lock" },
        { name: "Geo Mapping" },
      ],
    },
    {
      name: "REPORTING",
      img: "https://mui.com/static/branding/product-core-light.svg",
      subData: [
        { name: "Loan Wise Reports" },
        { name: "Pending Dues Reports" },
        { name: "Customer Wise Reports" },
        { name: "Day-Wise Collection Reports" },
      ],
    },
    {
      name: "INTEGRATIONS",
      img: "https://mui.com/static/branding/product-advanced-light.svg",
      subData: [
        { name: "Net Banking" },
        { name: "eNach" },
        { name: "UPI" },
        { name: "International Cards" },
        { name: "RuPay" },
        { name: "Wallet (Gpay)" },
      ],
    },
    {
      name: "CHANNELS",
      img: "https://mui.com/static/branding/product-templates-light.svg",
      subData: [
        { name: "Web" },
        { name: "Android" },
        { name: "iOS (coming soon)" },
        { name: "123UPIPAY" },
      ],
    },
    {
      name: "SUPPORT",
      img: "https://mui.com/static/branding/product-designkits-light.svg",
      subData: [{ name: "Freshworks" }, { name: "In-app support" }],
    },
    {
      name: "TEAM MANAGEMENT",
      img: "https://mui.com/static/branding/product-toolpad-light.svg",
      subData: [
        { name: "Roles and Permissions" },
        { name: "Notes and other tools" },
        { name: "Staff Management" },
        { name: "Mobile and Web access" },
      ],
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderPlan = (plan) => (
    <Grid item xs={6} sm={6} md={3} key={plan.name}>
      <Box
        border={
          plan?.id == loggedinUser?.company?.planId
            ? "1px solid green"
            : "1px solid #e5e9fe"
        }
        borderRadius="5px"
        padding="10px"
      >
        <Stack
          alignItems="center"
          minHeight="300px"
          justifyContent="space-between"
        >
          <Stack alignItems="center" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                src={plan.image}
                alt={plan.name}
                loading="eager"
                width="13"
                height="15"
              />
              <Typography
                variant="body2"
                fontWeight="bold"
                fontFamily="monospace"
              >
                {plan.name}
              </Typography>
            </Stack>
            {plan.id == 0 && trialActive && (
              <Chip
                label={`${trialDays} Days Left`}
                variant="outlined"
                color="error"
                size="small"
              />
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                fontFamily={"fantasy"}
                variant="h6"
                style={{
                  textDecorationLine: "line-through",
                  color: "#afafb3",
                }}
              >
                {getCurrencySymbol(countryCurrency?.symbol)}
                {plan.originalPrice}
              </Typography>
              <Typography variant="h5" fontFamily="fantasy">
                {getCurrencySymbol(countryCurrency?.symbol)}
                {plan.discountedPrice}
              </Typography>
              <Typography variant="body2">/ year</Typography>
            </Stack>
          </Stack>
          <Typography
            variant="button"
            fontFamily={"sans-serif"}
            style={{
              padding: "5px",
              border: "1px solid #000",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: 200,
            }}
          >
            {plan?.centerBoxText}
          </Typography>
          <Stack spacing={0.7} marginTop="5px" alignItems="center">
            <Tooltip
              title={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    1 User means only SuperAdmin
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    3 Users means 1 SuperAdmin & 2 Admin/CollectionAgent
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    5 Users means 1 SuperAdmin & 4 Admin/CollectionAgent
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    Unlimited Users means 1 Super Admin and Unlimited
                    Admin/CollectionAgent
                  </p>
                </div>
              }
            >
              <Info />
            </Tooltip>
            {plan.features.map((feature, index) => (
              <Typography key={index} variant="body1">
                {feature}
              </Typography>
            ))}
          </Stack>
          {plan?.id == loggedinUser?.company?.planId ? (
            <Button
              className="muiCustomButton"
              variant="outlined"
              style={{
                color: "green",
                borderColor: "green",
                boxShadow: "inherit",
                fontWeight: "bold",
              }}
              endIcon={<ChevronRight />}
              disabled
            >
              ACTIVE
            </Button>
          ) : loggedinUser?.company?.planId != "0" ? (
            <Button
              className="muiCustomButton"
              variant="outlined"
              endIcon={<SupportAgent />}
              onClick={handleClickOpenSupport}
            >
              Contact Support
            </Button>
          ) : (
            <Button
              className="muiCustomButton"
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={() => {
                localStorage.setItem(
                  "pricingData",
                  JSON.stringify({
                    priceId: plan?.id,
                    price: plan?.discountedPrice,
                  })
                );
                setModalValue({
                  planName: plan?.name,
                  originalPrice: plan?.originalPrice,
                  discountedPrice: plan?.discountedPrice,
                });
                setTimeout(() => {
                  // history.push("/paymentSubscription");
                  handleClickOpen();
                }, 100);
              }}
            >
              Buy Now
            </Button>
          )}
        </Stack>
      </Box>
    </Grid>
  );

  const yesCheck = () => (
    <Grid item xs={6} sm={6} md={3}>
      <Stack alignItems="center" justifyContent="space-between">
        <img
          src={"https://mui.com/static/branding/pricing/yes-light.svg"}
          alt="check"
          loading="eager"
        />
      </Stack>
    </Grid>
  );

  const noCheck = () => (
    <Grid item xs={6} sm={6} md={3}>
      <Stack alignItems="center" justifyContent="space-between">
        <img
          src={"https://mui.com/static/branding/pricing/no-light.svg"}
          alt="check"
          loading="eager"
        />
      </Stack>
    </Grid>
  );

  return (
    <>
      <Sidebar />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingInline={"100px"}
        paddingBlock={"10px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <PaymentSubscription modalValue={modalValue} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSupport}
        onClose={handleCloseSupport}
        aria-labelledby="alert-dialog-title-support"
        aria-describedby="alert-dialog-description-support"
      >
        <Stack
          sx={{ padding: 2 }}
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            href="tel:8667016575"
            color="primary"
            startIcon={<Call fontSize="30" />}
          >
            Call Us
          </Button>
          <Button
            href="https://wa.me/918667016575?text=Hello,%20I%20would%20like%20to%20learn%20about%20LendStack."
            color="success"
            startIcon={<WhatsApp fontSize="30" />}
          >
            WhatsApp Us
          </Button>
          <Button
            href="mailto:cofounder@lendstack.app"
            color="error"
            startIcon={<Email fontSize="30" />}
          >
            Mail Us
          </Button>
        </Stack>
      </Dialog>

      <Container style={{ paddingBlock: "10px", paddingLeft: "100px" }}>
        <Typography
          variant="h1"
          textAlign={"center"}
          fontSize={50}
          fontWeight={700}
        >
          Pricing Plans
        </Typography>
        <Typography
          style={{ marginBlock: 10 }}
          textAlign={"center"}
          variant="body2"
          fontSize={"20px"}
        >
          We strive to provide the affordable and most feature rich
          <br />
          loan management solutions in the market
        </Typography>
        {matches ? (
          <Grid container marginTop={2} spacing={2}>
            {plansData.map((plan) => renderPlan(plan))}
          </Grid>
        ) : (
          <>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                // variant="fullWidth"
              >
                <Tab value={"0"} label="Free" />
                <Tab value={"1"} label="Starter" />
                <Tab value={"2"} label="Business Plus" />
                <Tab value={"3"} label="Unlimited" />
              </Tabs>
            </Box>
            {plansData.map((plan) => plan.id == value && renderPlan(plan))}
          </>
        )}

        {value != 0 &&
          salientFeaturesData?.map((data) => (
            <Stack key={data?.name} marginTop={4}>
              <Box
                border={"1px solid #e5e9fe"}
                borderRadius={"10px"}
                padding={"10px"}
                boxShadow={1}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <img src={data?.img} alt="Heading" loading="eager" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    {data?.name}
                  </Typography>
                </Stack>
              </Box>
              {data?.subData?.map((item) => (
                <Stack key={item?.name}>
                  <Grid container spacing={4} paddingY={"15px"}>
                    <Grid item xs={6} sm={6} md={3} alignItems="center">
                      <Typography variant="body2" fontSize={15}>
                        {item?.name}
                      </Typography>
                    </Grid>
                    {matches
                      ? item?.data?.map((val) =>
                          val == "Yes" ? yesCheck() : noCheck()
                        )
                      : item?.data[parseInt(value) - 1] == "Yes"
                      ? yesCheck()
                      : noCheck()}
                  </Grid>
                  <Divider />
                </Stack>
              ))}
            </Stack>
          ))}

        {commonPlanData?.map((data) => (
          <Stack key={data?.name} marginTop={4}>
            <Box
              border={"1px solid #e5e9fe"}
              borderRadius={"10px"}
              padding={"10px"}
              boxShadow={1}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <img src={data?.img} alt="Heading" loading="eager" />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  {data?.name}
                </Typography>
              </Stack>
            </Box>
            {data?.subData?.map((item) => (
              <Stack key={item?.name}>
                <Grid container spacing={4} paddingY={"15px"}>
                  <Grid item xs={6} sm={6} md={3} alignItems="center">
                    <Typography variant="body2" fontSize={15}>
                      {item?.name}
                    </Typography>
                  </Grid>
                  {yesCheck()}
                  {matches && yesCheck()}
                  {matches && yesCheck()}
                </Grid>
                <Divider />
              </Stack>
            ))}
          </Stack>
        ))}
      </Container>
    </>
  );
};

export default Pricing;
