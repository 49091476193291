import "../login.scss";
import "react-toastify/dist/ReactToastify.css";

import React, { Component } from "react";
import { Card, Grid, Button, Input } from "@mui/material";
import { toast } from "react-toastify";
import loginimage from "../../../assets/images/loginimage.svg";
import GooglePlay from "../../../assets/images/Group 28315.svg";
import AppStore from "../../../assets/images/Group 28316.svg";
import { BaseUrl } from "../../../Environment";
import { auth } from "../../../firebase/config";
import LoadingButton from "@mui/lab/LoadingButton";

const axios = require("axios");
class OtpSignin extends Component {
  constructor() {
    super();
    this.state = {
      mobilenum: "",
      validnum: true,
      numError: "none",
      loading: false,
      otpSent: false,
      phoneNumber: "",

      otp: "",
      validOtp: true,
      otpError: "none",
      loadingOtp: false,
    };
  }

  validateNum(mobilenum) {
    const re = /^\d{10}$/g;
    return re.test(mobilenum);
  }
  validNum = (event) => {
    const mobilenum = event.target.value;
    const numVaild = this.validateNum(mobilenum);
    this.setState({
      mobilenum: event.target.value,
      validNum: numVaild,
    });
    if (numVaild === false) {
      this.setState({
        numError: "flex",
      });
    } else {
      this.setState({
        numError: "none",
      });
    }
  };

  requestOTPFromServer = async (phoneNumber) => {
    try {
      const response = await axios.post(
        BaseUrl + "/user/sendOtp",
        {
          userPhone: phoneNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        this.setState({ otpSent: true, phoneNumber: phoneNumber });
      } else {
        console.log("response.data.message", response.data.message);
        // todo handle error
      }
    } catch (error) {
      console.log("requestOTPFromServer", "error", JSON.stringify(error));
    } finally {
      this.setState({ loading: false });
    }
  };

  onGetOTPClicked = () => {
    this.setState({ loading: true });
    this.requestOTPFromServer(`+${JSON.parse(localStorage.getItem("country"))?.code}${this.state.mobilenum}`);
  };

  validateOTP(otpDigit) {
    const re = /^\d{4}$/g;
    return re.test(otpDigit);
  }

  onOtpChanged = (e) => {
    const otpDigit = e.target.value;
    const otpVaild = this.validateOTP(otpDigit);
    this.setState({
      otp: e.target.value,
      validOtp: otpVaild,
    });
    if (otpVaild === false) {
      this.setState({
        otpError: "flex",
      });
    } else {
      this.setState({
        otpError: "none",
      });
    }
  };

  onVerifyOTPClicked = () => {
    this.setState({ loadingOtp: true });
    this.verifyOTPWithServer(this.state.otp);
  };

  verifyOTPWithServer = async (otp) => {
    try {
      const response = await axios.post(
        BaseUrl + "/user/verifyMobileOtp",
        {
          userPhone: this.state.phoneNumber,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response.data &&
        response.data.status === true &&
        response.data.firebaseToken
      ) {
        // login with custom token firebase
        const firebaseToken = response.data.firebaseToken;
        await auth.signInWithCustomToken(firebaseToken);
        localStorage.setItem("defaultPhone", JSON.stringify(this.state.phoneNumber));
      } else {
        console.log("response.data.message", response.data);
        // todo handle error
      }
    } catch (error) {
      console.log("requestOTPFromServer", "error", error);
    } finally {
      console.log("Verified OTP");
      this.setState({ loadingOtp: false });
    }
  };

  componentDidMount() {}

  render() {
    toast.configure({});
    const {
      mobilenum,
      otpSent,
      loading,
      loadingOtp,
      numError,
      otp,
      otpError,
      phoneNumber,
    } = this.state;
    return (
      <Grid container className="loginPage dBlockCss">
        <Grid item md={6} className="paddingLoginGrids">
          <div>
            <div className="firstH3 TextColour">Collect like a PRO!</div>
            <div className="firstH4 TextColour">
              Digital Bharat ka Digital Khata
            </div>
          </div>
          <div>
            <div>
              <img src={loginimage} alt="login" className="loginIMage" />
              <div className="secondH4">
                Digital Ledger Account (Udhar Bahi Khata book) for Credit,
                Lending and Hisab Khitab for small lenders, grocers and
                shopkeepers
              </div>
              <div className="storeButtons">
                <div className="storeButtonsOne">
                  <a href="https://play.google.com/store/apps/details?id=com.lendstack" target="_blank" className="googlePlay">
                    <img
                      src={GooglePlay}
                      alt="googlePlay"
                      className="googlePlay"
                    />
                  </a>
                  <div className="appStore">
                    <img src={AppStore} alt="appStore" className="appStore" />
                  </div>
                </div>
                <div className="clickHere">
                  Read our{" "}
                  <a
                    target="_blank"
                    href="https://lendstack.app/#/termsofservice"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://lendstack.app/#/privacypolicy"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} className="paddingLoginGrids">
          <Card className="CardDivLogin">
            {otpSent ? (
              <div className="CardDiv4">
                <div className="CardDiv5 HighlightTextColour textLeft">
                  <div className="firstH4 TextColour textLeft">
                    Enter the OTP received on your mobile number {phoneNumber}
                  </div>
                </div>

                <div
                  className={"form-group" + (!otp ? " has-error" : "")}
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    type="number"
                    placeholder="Enter OTP"
                    required
                    name="otp"
                    className="form-control inputBoxContent Fonts SizeFont"
                    fullWidth
                    value={otp}
                    onChange={this.onOtpChanged}
                  />
                  <span style={{ display: otpError }} className="help-block">
                    OTP must be 4 digit.
                  </span>
                  {!otp && (
                    <div
                      className="help-block"
                      style={{ marginRight: "12rem" }}
                    >
                      OTP is required
                    </div>
                  )}
                </div>
                <div className="btnDiv" style={{ marginTop: 10 }}>
                  <LoadingButton
                    variant="outlined"
                    fullWidth
                    className="Fonts btnSizeFont btn"
                    onClick={this.onVerifyOTPClicked}
                    loading={loadingOtp}
                  >
                    Proceed
                  </LoadingButton>
                </div>
              </div>
            ) : (
              <div className="CardDiv4">
                <div className="CardDiv5 HighlightTextColour textLeft">
                  <h4 style={{ margin: 0 }}>Welcome!</h4>
                  <div className="firstH4 TextColour textLeft">
                    Enter your mobile number to get your OTP.
                  </div>
                </div>

                <div
                  className={"form-group" + (!mobilenum ? " has-error" : "")}
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    type="number"
                    placeholder="10-digit mobile number without prefixes"
                    required
                    name="mobilenum"
                    className="form-control inputBoxContent Fonts SizeFont"
                    fullWidth
                    value={mobilenum}
                    /* onChange={(event) => this.setState({ phone: event.target.value })} */ onChange={
                      this.validNum
                    }
                  />
                  <span style={{ display: numError }} className="help-block">
                    Mobile Number must be 10 digit.
                  </span>
                  {!mobilenum && (
                    <div
                      className="help-block"
                      style={{ marginRight: "12rem" }}
                    >
                      Mobile Number is required
                    </div>
                  )}
                </div>
                <div className="btnDiv" style={{ marginTop: 10 }}>
                  <LoadingButton
                    variant="outlined"
                    disabled={!mobilenum?true : false}
                    fullWidth
                    className="Fonts btnSizeFont btn"
                    onClick={this.onGetOTPClicked}
                    loading={loading}
                  >
                    Next
                  </LoadingButton>
                </div>
                <div className="TextColour linkFontSize textLeft">
              By clicking, I accept the Terms of Service and Privacy Policy.
             </div>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default OtpSignin;
