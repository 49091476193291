import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Paper,
  Tooltip,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import "date-fns";
import Select from "react-select";
import DateRangePicker from "react-daterange-picker";
import { extendMoment } from "moment-range";
import originalMoment from "moment";
import {
  CalendarMonth,
  DeleteSharp,
  EditSharp,
  Info,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import {
  getAllOtherIncomeDataAPI,
  getAllOtherIncomeTypeAPI,
  addOtherIncomeAPI,
  updateOtherIncomeAPI,
  deleteOtherIncomeAPI,
  addOtherIncomeTypeAPI,
  getAllBankAccountsDataAPI,
  addBankAccountAPI,
} from "../../service/service";
import "./OtherIncome.scss";
import Axios from "axios";
import { BaseUrl } from "../../Environment";

const moment = extendMoment(originalMoment);

const OtherIncome = () => {
  const history = useHistory();
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const optionsOfAccount = [{ label: "Cash", value: "0000" }];

  const filterArrays = (apiArr, staticArr) => {
    // Combine both arrays
    const combinedArr = [...apiArr, ...staticArr];

    // Group by label
    const groupedByLabel = combinedArr.reduce((acc, current) => {
      acc[current.label] = acc[current.label] || [];
      acc[current.label].push(current);
      return acc;
    }, {});

    // Filter groups to only include API data if both API and static data are present
    const filteredArr = Object.values(groupedByLabel).reduce((acc, group) => {
      const hasApiData = group.some((item) => apiArr.includes(item));
      const apiGroup = group.filter((item) => apiArr.includes(item));
      const staticGroup = group.filter((item) => staticArr.includes(item));

      if (hasApiData) {
        // If API data is present, use only API data
        acc.push(...apiGroup);
      } else {
        // If no API data, use static data
        acc.push(...staticGroup);
      }

      return acc;
    }, []);

    return filteredArr;
  };

  const WeeksData = [
    { label: "Monday of every week", value: "wd1" },
    { label: "Tuesday of every week", value: "wd2" },
    { label: "Wednesday of every week", value: "wd3" },
    { label: "Thursday of every week", value: "wd4" },
    { label: "Friday of every week", value: "wd5" },
    { label: "Saturday of every week", value: "wd6" },
    { label: "Sunday of every week", value: "wd7" },
  ];

  const MonthsData = [
    { label: "1st of every month", value: "d1" },
    { label: "2nd of every month", value: "d2" },
    { label: "3rd of every month", value: "d3" },
    { label: "4th of every month", value: "d4" },
    { label: "5th of every month", value: "d5" },
    { label: "6th of every month", value: "d6" },
    { label: "7th of every month", value: "d7" },
    { label: "8th of every month", value: "d8" },
    { label: "9th of every month", value: "d9" },
    { label: "10th of every month", value: "d10" },
    { label: "11th of every month", value: "d11" },
    { label: "12th of every month", value: "d12" },
    { label: "13th of every month", value: "d13" },
    { label: "14th of every month", value: "d14" },
    { label: "15th of every month", value: "d15" },
    { label: "16th of every month", value: "d16" },
    { label: "17th of every month", value: "d17" },
    { label: "18th of every month", value: "d18" },
    { label: "19th of every month", value: "d19" },
    { label: "20th of every month", value: "d20" },
    { label: "21st of every month", value: "d21" },
    { label: "22nd of every month", value: "d22" },
    { label: "23rd of every month", value: "d23" },
    { label: "24th of every month", value: "d24" },
    { label: "25th of every month", value: "d25" },
    { label: "26th of every month", value: "d26" },
    { label: "27nd of every month", value: "d27" },
    { label: "28th of every month", value: "d28" },
    { label: "29th of every month", value: "d29" },
    { label: "30th of every month", value: "d30" },
    { label: "31th of every month", value: "d31" },
    { label: "End of every month", value: "dLast" },
  ];

  const groupedOptions = [
    {
      label: "Weekly",
      options: WeeksData,
    },
    {
      label: "Monthly",
      options: MonthsData,
    },
  ];

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data?.label}</span>
      <span style={groupBadgeStyles}>{data.options?.length}</span>
    </div>
  );

  const otherIncomeStaticTypes = [
    { label: "Business Income", value: "S1" },
    { label: "Capital Gains", value: "S2" },
    { label: "Collateral Sale", value: "S3" },
    { label: "Interest Received", value: "S4" },
    { label: "Motor Vehicle Sale", value: "S5" },
    { label: "Passive Activities", value: "S6" },
    { label: "Rental Income", value: "S7" },
  ];

  const isRecurringOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const [payload, setPayload] = useState({
    amount: "",
    description: "",
    transactionDate: moment().format("YYYY-MM-DD"),
  });

  const [errors, setErrors] = useState({
    otherIncomeTypeId: null,
    amount: "",
    transactionDate: "",
    accountId: "",
  });

  const [labelOfType, setLabelOfType] = useState("");
  const [labelOfBank, setLabelOfBank] = useState("");
  const [bankAccountData, setBankAccountData] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [file, setFile] = useState("");
  const [loanId, setLoanId] = useState("");
  const [loansData, setLoansData] = useState([]);
  const [otherIncomeId, setOtherIncomeId] = useState(null);
  const [otherIncomeTypeId, setOtherIncomeTypeId] = useState(null);
  const [otherIncomeData, setOtherIncomeData] = useState([]);
  const [otherIncomeTypeData, setOtherIncomeTypeData] = useState([]);
  const [deleteOtherIncomeModal, setDeleteOtherIncomeModal] = useState(false);
  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [otherIncomeTypeFilter, setOtherIncomeTypeFilter] = useState(null);
  const [accountIdFilter, setAccountIdFilter] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [recurringDate, setRecurringDate] = useState("");

  const fetchLoansData = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const headers = {
      Authorization: "Bearer " + token,
    };

    const payload = {
      loanStatus: "Open",
      loanPaymentStatus: null,
      companyId: loggedinUser.companyId,
    };
    try {
      const resp = await Axios.post(BaseUrl + "/loan/getAllLoans", payload, {
        headers: headers,
      });

      if (resp?.status == 200 && resp?.data) {
        setLoansData(
          resp?.data?.map((item) => ({
            label: `${item?.customer?.firstName} ${item?.customer?.lastName} ${
              item?.customer?.cust_id ? `(${item?.customer.cust_id})` : ""
            }(Loan : ${item?.loanId})`,
            value: item?.loanId,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOtherIncomeData = async () => {
    try {
      const companyId = loggedinUser?.companyId;
      const userId = loggedinUser?.userId;
      const userType = loggedinUser?.userType;

      const OtherIncomeRes = await getAllOtherIncomeDataAPI({
        companyId,
        userId,
        userType,
        otherIncomeTypeFilter,
        accountIdFilter,
        startDateFilter: dateRange?.start
          ? dateRange.start.startOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
        endDateFilter: dateRange?.end
          ? dateRange.end.endOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
      });

      if (OtherIncomeRes?.status == 200 && OtherIncomeRes?.data) {
        setOtherIncomeData(OtherIncomeRes?.data);
      } else {
        setToastMsg({
          msg: "No data available.",
          time: 2000,
          variant: "error",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
        setOtherIncomeData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOtherIncomeTypesData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const OtherIncomeTypesRes = await getAllOtherIncomeTypeAPI(companyId);

      const valueLabelData = OtherIncomeTypesRes?.data?.map((item) => ({
        label: item.title,
        value: item.typeId,
      }));

      if (OtherIncomeTypesRes?.status == 200 && OtherIncomeTypesRes?.data) {
        setOtherIncomeTypeData(
          filterArrays(valueLabelData, otherIncomeStaticTypes)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBAData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const data = {
        companyId,
        searchQuery: "",
      };

      const resp = await getAllBankAccountsDataAPI(data);

      if (resp?.data?.length > 0) {
        setBankAccountData(resp?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLoansData();
    fetchBAData();
    fetchOtherIncomeTypesData();
  }, []);

  useEffect(() => {
    fetchOtherIncomeData();
  }, [otherIncomeTypeFilter, accountIdFilter, dateRange]); // Add dependencies as necessary

  const handleChange = (key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveOtherIncome = async () => {
    const userId = loggedinUser?.userId;
    const companyId = loggedinUser?.companyId;
    const { amount, description, transactionDate } = payload;

    const newErrors = {
      otherIncomeTypeId: !otherIncomeTypeId ? "This field is required" : "",
      amount: !payload.amount ? "This field is required" : "",
      transactionDate: !payload.transactionDate ? "This field is required" : "",
      accountId: !accountId ? "This field is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error != "");

    if (!hasErrors) {
      try {
        let catePayload, bankAccountPayload, cateRes, bankRes;

        if (labelOfType) {
          catePayload = {
            title: labelOfType,
            description: `Desc ${labelOfType}`,
            companyId,
            createdByUserId: userId,
            updatedByUserId: userId,
          };

          cateRes = await addOtherIncomeTypeAPI(catePayload);

          if (cateRes?.status == 200) {
            setLabelOfType("");
            setOtherIncomeTypeId(cateRes.data?.result?.typeId);
          }
        }

        if (labelOfBank) {
          bankAccountPayload = {
            code: "0000",
            bankName: labelOfBank,
            companyId,
            createdByUserId: userId,
            updatedByUserId: userId,
          };

          bankRes = await addBankAccountAPI(bankAccountPayload);

          if (bankRes?.status == 200) {
            setLabelOfBank("");
            setAccountId(bankRes.data.result?.accountId);
          }
        }

        let OtherIncomePayload = new FormData();

        OtherIncomePayload.append("amount", amount);
        OtherIncomePayload.append("description", description);
        OtherIncomePayload.append("transactionDate", transactionDate);
        OtherIncomePayload.append(
          "typeId",
          cateRes?.data?.result?.typeId ? cateRes?.data?.result?.typeId : otherIncomeTypeId
        );
        OtherIncomePayload.append("companyId", companyId);
        OtherIncomePayload.append("createdByUserId", userId);
        OtherIncomePayload.append("updatedByUserId", userId);
        OtherIncomePayload.append("loanId", loanId);
        OtherIncomePayload.append("isRecurring", isRecurring);
        OtherIncomePayload.append("recurringDate", isRecurring ? recurringDate : "");
        OtherIncomePayload.append(
          "accountId",
          bankRes?.data?.result?.accountId
            ? bankRes?.data?.result?.accountId
            : accountId
        );
        OtherIncomePayload.append("invoice", file);
        if (isUpdate) {
          OtherIncomePayload.append("seqId", otherIncomeId);
        }

        const res = isUpdate
          ? await updateOtherIncomeAPI(OtherIncomePayload)
          : await addOtherIncomeAPI(OtherIncomePayload);

        if (res?.status == 200) {
          setAddUpdateModal(false);
          setToastMsg({
            msg: `Other Income ${isUpdate ? "Updated" : "Added"} Successfully.`,
            time: 4000,
            variant: "success",
          });

          setTimeout(() => {
            handleOpen();
          }, 500);
          setTimeout(() => {
            fetchOtherIncomeTypesData();
            fetchBAData();
            fetchOtherIncomeData();
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleEdit = (data) => {
    console.log(data);
    setPayload({
      amount: data.amount,
      description: data.description,
      transactionDate: moment(data.transactionDate).format("YYYY-MM-DD"),
    });
    setAccountId(data?.accountId);
    setIsRecurring(data?.isRecurring);
    setRecurringDate(data?.recurringDate)
    setLoanId(data?.loanId);
    setFile(data?.invoice);
    setOtherIncomeId(data?.seqId);
    setOtherIncomeTypeId(data?.typeId);
    setIsUpdate(true);
    setAddUpdateModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteOtherIncomeAPI(otherIncomeId);
      setDeleteOtherIncomeModal(false);
      setToastMsg({
        msg: `Other Income Deleted Successfully.`,
        time: 3000,
        variant: "success",
      });

      setTimeout(() => {
        handleOpen();
      }, 500);

      setTimeout(() => {
        fetchOtherIncomeData();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const AccountsSelectData = [
    ...bankAccountData?.map((item) => ({
      label: item.bankName,
      value: item.accountId,
    })),
    ...optionsOfAccount,
  ];

  const uniqueAccounts = AccountsSelectData.reduce((acc, item) => {
    if (
      !acc.has(item.label) ||
      (acc.has(item.label) && acc.get(item.label).value == "0000")
    ) {
      acc.set(item.label, item);
    }
    return acc;
  }, new Map());

  const filteredDataAccounts = Array.from(uniqueAccounts.values());

  const AccountsFilterData = [
    ...bankAccountData?.map((item) => ({
      label: item.bankName,
      value: item.accountId,
    })),
  ];

  const combinedOptions = groupedOptions?.flatMap(item => item.options);

  return (
    loansData &&
    otherIncomeTypeData && (
      <>
        <Sidebar />

        <Snackbar
          open={open}
          autoHideDuration={toastMsg.time}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={toastMsg.variant}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastMsg.msg}
          </Alert>
        </Snackbar>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"10px 100px"}
        >
          <img src={companylogo} alt="logo" />
        </Stack>

        <Stack
          className="otherincomepage"
          spacing={1}
          padding={"10px 20px 10px 100px"}
        >
          <Grid container>
            <Grid item md={6} sm={4}></Grid>
            <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
              <Button
                className="muiCustomButton"
                variant="outlined"
                onClick={() => history.push("otherincometypes")}
              >
                Manage Income Types
              </Button>
            </Grid>
            <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
              <Button
                className="GreenBTN"
                variant="filled"
                onClick={() => {
                  setPayload({
                    amount: "",
                    description: "",
                    transactionDate: moment().format("YYYY-MM-DD"),
                  });
                  setRecurringDate("");
                  setOtherIncomeTypeId(null);
                  setOtherIncomeId(null);
                  setLoanId("");
                  setAccountId("");
                  setIsRecurring(false);
                  setIsUpdate(false);
                  setAddUpdateModal(true);
                }}
              >
                + Add Other Income
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ width: "98%", padding: "10px", backgroundColor: "#fff" }}>
            <Typography
              variant="body2"
              marginBlock={1}
              fontSize={15}
              fontWeight={700}
            >
              Filter Other Income
            </Typography>
            <Grid container alignItems={"center"}>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={otherIncomeTypeData}
                  placeholder="Select Income Types"
                  value={
                    otherIncomeTypeFilter &&
                    otherIncomeTypeData?.find(
                      (item) => item.value == otherIncomeTypeFilter
                    )
                  }
                  onChange={(val) => setOtherIncomeTypeFilter(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={AccountsFilterData}
                  placeholder="Select Accounts"
                  value={
                    accountIdFilter &&
                    AccountsFilterData?.find(
                      (item) => item.value == accountIdFilter
                    )
                  }
                  onChange={(val) => setAccountIdFilter(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Button
                  onClick={() => setDateRangeModal(true)}
                  variant="outlined"
                  fullWidth
                  startIcon={<CalendarMonth />}
                >
                  Select Date Range
                </Button>
              </Grid>
            </Grid>
            <Stack
              mt={2}
              mr={1}
              direction={"row"}
              alignSelf={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button
                className="muiCustomButton"
                onClick={() => {
                  setAccountIdFilter("");
                  setOtherIncomeTypeFilter(null);
                  setDateRange();
                }}
                variant="outlined"
              >
                Reset
              </Button>
            </Stack>
          </Box>

          <Card style={{ marginTop: "10px" }}>
            <div className="tableLaylout">
              <Table aria-label="sticky table" className="table">
                <TableHead>
                  <TableRow className="tablHeadRowPayment">
                    <TableCell className="tablHeadCellPayment">
                      Transaction Date
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Income Type
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Amount
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Accounts
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Description
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Is Recurring
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Recurring Type
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otherIncomeData.map((data) => {
                    const {
                      seqId,
                      amount,
                      description,
                      accountId,
                      isRecurring,
                      recurringDate,
                      transactionDate,
                      typeId,
                    } = data;
                    const selectedType = otherIncomeTypeData.find(
                      (item) => item.value == typeId
                    );

                    const selectedAccount = bankAccountData.find(
                      (item) => item.accountId == accountId
                    );

                    const selectedRecurring = [
                      ...WeeksData,
                      ...MonthsData,
                    ].find((item) => item.value == recurringDate);

                    return (
                      <TableRow key={seqId} className="tableRow">
                        <TableCell>
                          {originalMoment(transactionDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{selectedType?.label}</TableCell>
                        <TableCell>{amount}</TableCell>
                        <TableCell>{selectedAccount?.bankName}</TableCell>
                        <TableCell>{description}</TableCell>
                        <TableCell>{isRecurring ? "Yes" : "No"}</TableCell>
                        <TableCell>{selectedRecurring?.label}</TableCell>
                        <TableCell>
                          <Stack direction={"row"} spacing={1}>
                            <IconButton
                              color="primary"
                              aria-label="update"
                              onClick={() => handleEdit(data)}
                            >
                              <EditSharp />
                            </IconButton>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => {
                                setOtherIncomeId(seqId);
                                setDeleteOtherIncomeModal(true);
                              }}
                            >
                              <DeleteSharp />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Stack>

        {/* Delete Other Income Modal */}
        <Dialog
          open={deleteOtherIncomeModal}
          onClose={() => setDeleteOtherIncomeModal(false)}
        >
          <DialogTitle>Delete Other Income</DialogTitle>
          <DialogContent>
            <Typography fontSize={15} variant="h6">
              Are you sure, You want to delete this Other Income record?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setDeleteOtherIncomeModal(false)}
              >
                Cancel
              </Button>
              <Button color="error" variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        {/* Date Range Modal */}
        <Dialog open={dateRangeModal} onClose={() => setDateRangeModal(false)}>
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <DateRangePicker
              value={dateRange}
              onSelect={setDateRange}
              singleDateRange
            />
            <Stack spacing={1} marginTop={1}>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                Start Date:{" "}
                {dateRange?.start
                  ? dateRange.start.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                End Date:{" "}
                {dateRange?.end
                  ? dateRange.end.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Add/Update Modal */}
        <Dialog
          fullWidth
          maxWidth="sm"
          open={addUpdateModal}
          onClose={() => setAddUpdateModal(false)}
        >
          <DialogTitle>{isUpdate ? "Update" : "Add"} Other Income</DialogTitle>
          <DialogContent>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
                <Stack spacing={0.5} marginBottom={1}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant="body1">
                      Other Income Type
                      <span style={{ color: "red", fontSize: 15 }}>*</span>
                    </Typography>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("otherincometypes");
                      }}
                      style={{ fontSize: 12, cursor: "pointer" }}
                      underline="none"
                    >
                      {"Add/Edit Income Types"}
                    </Link>
                  </Stack>
                  <Select
                    options={otherIncomeTypeData}
                    value={otherIncomeTypeData?.find(
                      (item) => item.value == otherIncomeTypeId
                    )}
                    onChange={(item) => {
                      if (typeof item?.value != "string") {
                        setOtherIncomeTypeId(item?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          otherIncomeTypeId: null,
                        }));
                      } else {
                        setLabelOfType(item?.label);
                        setOtherIncomeTypeId(item?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          otherIncomeTypeId: null,
                        }));
                      }
                    }}
                    placeholder="Income Type"
                  />
                  {errors.otherIncomeTypeId && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.otherIncomeTypeId}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">
                    Amount
                    <span style={{ color: "red", fontSize: 15 }}>*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    value={payload.amount}
                    inputMode="numeric"
                    type="number"
                    placeholder="Enter Other Income Amount"
                    onChange={(e) => handleChange("amount", e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  {errors.amount && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.amount}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">
                    Transaction Date
                    <span style={{ color: "red", fontSize: 15 }}>*</span>
                  </Typography>
                  <input
                    type="date"
                    style={{
                      borderWidth: "1px",
                      borderColor: "lightgray",
                      borderRadius: "4px",
                      width: "95%",
                      padding: "10px",
                    }}
                    value={payload.transactionDate}
                    onChange={(e) =>
                      handleChange("transactionDate", e.target.value)
                    }
                  />
                  {errors.transactionDate && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.transactionDate}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant="body1">
                      Accounts
                      <span style={{ color: "red", fontSize: 15 }}>*</span>
                    </Typography>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("bankaccountmanagement");
                      }}
                      style={{ fontSize: 12, cursor: "pointer" }}
                      underline="none"
                    >
                      {"Add/Update Bank Accounts"}
                    </Link>
                  </Stack>
                  <Select
                    options={filteredDataAccounts}
                    value={
                      accountId &&
                      filteredDataAccounts.find(
                        (item) => item.value == accountId
                      )
                    }
                    onChange={(val) => {
                      if (val.value == "0000") {
                        setLabelOfBank(val?.label);
                        setAccountId(val?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          accountId: null,
                        }));
                      } else {
                        setAccountId(val?.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          accountId: null,
                        }));
                      }
                    }}
                    placeholder="Select Accounts"
                  />
                  {errors.accountId && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.accountId}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">Invoice/Receipt</Typography>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    style={{
                      border: "1px solid lightgray",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    onChange={handleFileChange}
                  />
                  {typeof file == "string" && <span>{file}</span>}
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">Description</Typography>
                  <TextField
                    hiddenLabel
                    value={payload.description}
                    placeholder="Enter Other Income Description"
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Stack spacing={1} direction={"row"} alignItems={"center"}>
                    <Typography variant="body1">Link to Loan</Typography>
                    <Tooltip title="If this income is for a specific loan, you can select the loan below. Otherwise leave the below empty.">
                      <IconButton>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Select
                    options={loansData}
                    value={loansData.find((item) => item.value == loanId)}
                    onChange={(val) => setLoanId(val?.value)}
                    placeholder="Select Loan"
                  />
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">Is Recurring?</Typography>
                  <Select
                    options={isRecurringOptions}
                    value={isRecurringOptions.find(
                      (item) => item.value == isRecurring
                    )}
                    onChange={(val) => setIsRecurring(val?.value)}
                    placeholder="Select Is Recurring?"
                  />
                </Stack>
                <Stack spacing={0.5} marginBottom={1}>
                  <Typography variant="body1">Recurring Type</Typography>
                  <Select
                    value={
                      recurringDate &&
                      combinedOptions.find(
                        (item) => item?.value == recurringDate
                      )
                    }
                    isDisabled={isRecurring == false}
                    onChange={(val) => setRecurringDate(val?.value)}
                    placeholder="Select Recurring Type"
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                  />
                </Stack>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setAddUpdateModal(false)}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSaveOtherIncome}>
                {isUpdate ? "Update" : "Add"}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default OtherIncome;
