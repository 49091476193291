import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import { BaseUrl } from "../Environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IconButton,
  Popper,
  Typography,
  Paper,
  Button,
  Fade,
  CircularProgress,
} from "@mui/material";
import { DeleteOutline, InfoOutlined } from "@material-ui/icons";
import * as XLSX from "xlsx";
import moment from "moment";
import { saveAs } from "file-saver";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    backgroundColor: "#fff",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "5px",
    margin: "10px",
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(2),
  },
}));

const LoanCsvImport = () => {
  const history = useHistory();

  let mainData = [];
  const invalidRows = [];
  const mandatoryErrors = [];

  const classes = useStyles();
  const [loans, setLoans] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [file, setFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getVerifiedCustomers = async () => {
    let loggedinUser = localStorage.getItem("loggedinUserCompany");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
    axios
      .get(
        BaseUrl +
          "/customer/getVerifiedCustomers?name=&companyId=" +
          loggedinUser,
        {
          headers: headers,
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setCustomers(
            resp.data?.map((data) => {
              return {
                customerId: data?.customerId,
                cust_id: data?.cust_id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getLoanTypes = async () => {
    let loggedinUser = localStorage.getItem("loggedinUserCompany");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
    axios
      .get(
        BaseUrl +
          "/loanType/getAllLoanTypes?loanType=&companyId=" +
          loggedinUser,
        {
          headers: headers,
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setLoanType(
            resp.data?.map((data) => {
              return {
                loanTypeId: data?.loanTypeId,
                loanType: data?.loanType,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const getAllCA = async () => {
    let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
    const headers = {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };

    axios
      .get(
        `${BaseUrl}/user/getAllUsers?userName=&companyId=${loggedinUser?.companyId}`,
        {
          headers,
        }
      )
      .then((response) => {
        setUsers(
          response.data?.result?.map((data) => {
            return {
              name: `${data?.firstName} ${data?.lastName}`,
              collectorId: data?.userId,
            };
          })
        );
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  useEffect(() => {
    getAllCA();
    getLoanTypes();
    getVerifiedCustomers();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleImport = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        const mandatoryFields = [
          "totalAmountToCollect",
          "loanAmount",
          "collectionAgent",
          "custId",
          "Accumulate",
          "autoUpdate",
          "CollectionType",
          "loanDuration",
          "loanTypeId",
          "method",
          "startDate",
        ];

        jsonData.forEach((row, index) => {
          let hasMandatoryError = false;

          const {
            totalAmountToCollect,
            loanAmount,
            collectionAgent,
            custId,
            Accumulate,
            autoUpdate,
          } = row;

          const isUserExist = users?.filter(
            (data) => data?.name == collectionAgent
          );
          const isCustomerExist = customers?.filter(
            (data) => data?.cust_id == custId
          );

          // Check for mandatory fields
          mandatoryFields.forEach((field) => {
            if (!row[field]) {
              mandatoryErrors.push({
                columnName: field,
                rowNumber: index + 1,
                errorMessage: `${field} is a mandatory field and cannot be empty`,
              });
              hasMandatoryError = true;
            }
          });

          if (!hasMandatoryError) {
            // Sample validations (modify as per your requirements)
            // Validation 1: total amount to collect should be >= loan amount
            if (totalAmountToCollect < loanAmount) {
              invalidRows.push({
                rowNumber: index + 1,
                errorMessage: `Total amount to collect must be greater than or equal to loan amount`,
              });
            }

            // Validation 2: collectorId must match userId
            if (isUserExist <= 0) {
              invalidRows.push({
                rowNumber: index + 1,
                errorMessage: `Incorrect Collection Agent name provided`,
              });
            }

            // Validation 3: Check if customerId is correct
            if (isCustomerExist <= 0) {
              invalidRows.push({
                rowNumber: index + 1,
                errorMessage: `Incorrect Cust ID provided`,
              });
            }

            // Validation 4: Accumulate and autoUpdate condition
            if (Accumulate === "Yes" && autoUpdate !== "No") {
              invalidRows.push({
                rowNumber: index + 1,
                errorMessage: `If accumulate is 'Yes', autoUpdate must be 'No'`,
              });
            }
          }

          // If all validations passed, proceed with handling the entry
          if (mandatoryErrors.length === 0 && invalidRows.length === 0) {
            mainData.push(row);
          }
        });

        if (mandatoryErrors.length > 0) {
          // Generate Excel workbook for mandatory field errors
          const errorWorkbook = XLSX.utils.book_new();
          const errorSheet = XLSX.utils.aoa_to_sheet([
            ["Column Name", "Row Number", "Error Message"],
            ...mandatoryErrors.map(({ columnName, rowNumber, errorMessage }) => [
              columnName,
              rowNumber,
              errorMessage,
            ]),
          ]);
          XLSX.utils.book_append_sheet(errorWorkbook, errorSheet, "Validation Errors");

          // Generate and download the Excel file for mandatory field errors
          const wbout = XLSX.write(errorWorkbook, { bookType: "xlsx", type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });
          const fileNameM = "MandatoryFieldErrors.xlsx";

          toast.error(
            `The file ${file.name} has been validated for mandatory errors. Please resolve the errors in the ${fileNameM} in Downloads directory.`,
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );

          saveAs(blob, fileNameM);
        } else if (invalidRows.length > 0) {
          const errorWorkbook = XLSX.utils.book_new();
          const errorSheet = XLSX.utils.aoa_to_sheet([
            ["Row Number", "Error Message"],
            ...invalidRows.map(({ rowNumber, errorMessage }) => [
              rowNumber,
              errorMessage,
            ]),
          ]);
          XLSX.utils.book_append_sheet(
            errorWorkbook,
            errorSheet,
            "Validation Errors"
          );

          // Generate and download the Excel file
          const wbout = XLSX.write(errorWorkbook, {
            bookType: "xlsx",
            type: "array",
          });
          const blob = new Blob([wbout], { type: "application/octet-stream" });
          const fileName = "LoansValidationErrors.xlsx";

          toast.error(
            `The file ${file.name} has been validated for validation errors. Please resolve the issues in the ${fileName} in Downloads directory.`,
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );

          saveAs(blob, fileName);
        } else {
          setLoans(mainData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const clearUploadedFile = () => {
    setFile(null);
    // If you need to clear the input field, you can do that too
    const fileInput = document.getElementById("contained-button-file");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  function excelNumericToDate(excelNumeric) {
    // Excel base date
    var baseDate = new Date("1899-12-30");

    // Calculate the milliseconds corresponding to the excel numeric value
    var milliseconds = excelNumeric * 24 * 60 * 60 * 1000;

    // Add the milliseconds to the base date
    var date = new Date(baseDate.getTime() + milliseconds);

    return date;
  }

  const postLoan = async (data) => {
    let loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));
    const userId = localStorage.getItem("userid");

    const collectorId = users?.filter(
      (val) => val?.name == data?.collectionAgent
    );
    const loanTypeId = loanType?.filter(
      (val) => val?.loanType == data?.loanTypeId
    );
    const customerId = customers?.filter((val) => val?.cust_id == data?.custId);

    var startDate = excelNumericToDate(data?.startDate);

    let calculatePayload = {
      loanTypeId: loanTypeId[0]?.loanTypeId,
      method: data?.method,
      penalty: data?.penalty ?? 0,
      loanAmount: data?.loanAmount ?? 0,
      loanTenureType: data?.CollectionType,
      loanTenure: data?.loanDuration ?? 0,
      collectionAmount: data?.totalAmountToCollect / data?.loanDuration ?? 0,
      nextDueDate: moment(startDate)?.format("YYYY-MM-DD HH:mm:ss"),
      startDate: moment(startDate)?.format("YYYY-MM-DD HH:mm:ss"),
      totalAmountToCollect: data?.totalAmountToCollect,
      processFee: data?.processFee ?? 0,
      Accumulate: data?.Accumulate,
      autoUpdate: data?.autoUpdate,
      createdByUserId: userId,
      updatedByUserId: userId,
      customerId: customerId[0]?.customerId,
      loanStatus: "Open",
      companyId: loggedinUser.companyId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };
    axios
      .post(BaseUrl + "/loan/calculate", calculatePayload, {
        headers: headers,
      })
      .then((resp) => {
        if (resp.data) {
          let scheduleArr = resp.data?.map((item) => ({
            ...item,
            openingBalance: item.openingBalance,
            scheduledDate: item.scheduledDate,
            loanTenure: parseInt(data?.loantenure),
            closingBalance: item.closingBalance,
            collectionAmount: item.collectionAmount,
            collectorId: collectorId[0]?.collectorId,
            createdByUserId: userId,
            updatedByUserId: userId,
            customerId: customerId[0]?.customerId,
            companyId: loggedinUser.companyId,
          }));

          let collectionArr = resp.data?.map((item, index) => ({
            dueDate: item.scheduledDate,
            nextSchedule:
              resp.data.length === index + 1
                ? resp.data[index].scheduledDate
                : resp.data[index + 1].scheduledDate,
            Installment: item.collectionAmount,
            collectionAmount: item.collectionAmount,
            openingBalance: item.openingBalance,
            closingBalance: item.closingBalance,
            collectionStatus: null,
            PenaltyApplied: null,
            collectorId: collectorId[0]?.collectorId,
            createdByUserId: userId,
            updatedByUserId: userId,
            customerId: customerId[0]?.customerId,
            companyId: loggedinUser.companyId,
          }));

          let userdetails = {
            ...calculatePayload,
          };

          userdetails.loanSchedules = scheduleArr;
          userdetails.loancollections = collectionArr;
          userdetails.collectorId = collectorId[0]?.collectorId;
          userdetails.loanPaymentStatus = "Due";
          userdetails.processFee = data?.processFee;
          userdetails.Accumulate = data?.Accumulate;
          userdetails.autoUpdate = data?.autoUpdate;

          const headers = {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          };
          axios
            .post(BaseUrl + "/loan/postLoan", userdetails, {
              headers: headers,
            })
            .then((res) => {
              if (res?.data) {
                if(resp.data?.customerId){
                  toast.success("Borrower Details Filled Successfully!", {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                  });
                } else {
                  // Alert.alert("Warning", res.data?.message)
                  toast.warn(resp.data?.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                  });
                }
                // console.log("Loan Added Successfully.");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (loans?.length > 0) {
      setLoading(true);
      loans.forEach((loan) => {
        postLoan(loan);
      });

      setTimeout(() => {
        setLoading(false);
        // toast.success(`All loans are created successfully.`, {
        //   position: "top-center",
        //   autoClose: 2000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        // });
        history.push("/customer");
      }, 1000);
    }
  }, [loans]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      {loading ? <CircularProgress /> :
      <>
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200, width: "100%" }}
        open={open}
        onClick={() => setOpen(!open)}
        anchorEl={anchorEl}
        placement={"bottom"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Important points to note</Typography>
              <Typography sx={{ pl: 1 }}>
                1. Please add your data to the above template for loans and
                customers...
              </Typography>
              <Typography sx={{ pl: 1 }}>
                2. First Upload Customers data followed by loans data.
              </Typography>
              <Typography sx={{ pl: 1 }}>
                3. The following validations will be done by the app
                <Typography sx={{ pl: 1 }}>
                  a.Check for duplicates in borrowers within the Excel and get
                  downloaded as duplicates.xlsx File.
                  <br />
                  b.Check for duplicates in borrowers in Excel with DataBase and
                  get downloaded as duplicatesinDB File.
                  <br />
                  c. Amounttobe Collected should be greater than or equal to the
                  Lent Amount. <br />
                  d.Collection Agent First name and Last Name should exactly
                  match with the name in the User Management Screen.
                  <br />
                  e.While adding customers please provide a unique CustomerId to
                  each of your Borrowers and give respective customerId while
                  creating loans for that Borrower. <br />
                  f. When not paid, If you are planning to Accumulate the
                  current installment amount to the next installment,we have
                  disabled Auto Update for this.Please provide Auto Update as
                  No,if Accumulate is Yes.
                </Typography>
              </Typography>
              <Typography sx={{ pl: 1 }}>
                4. The above validations c,d,e and f will be downloaded as
                LoansValidationErrors.xlsx
              </Typography>
              <Typography sx={{ pl: 1 }}>
                5. Please recheck the excel with the above error files and
                import the entire file again.
              </Typography>
              <Typography sx={{ pl: 1 }}>
                6. Unless all the validation issues are resolved,the data will
                not be added to database.
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Paper
        className={classes.paper}
        elevation={3}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Typography component="p">
          Please read the{" "}
          <span
            onClick={handleClick}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            instructions
          </span>{" "}
          before proceeding to import file
        </Typography>
        <Typography mb={3} alignSelf={"flex-end"} component="a">
          <a
            href="https://drive.google.com/uc?export=download&id=1G43HuP7wx5yDyMR6KqEg5pI4IA8dYhw4"
            target="_blank"
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Sample template
          </a>{" "}
        </Typography>
        <Typography variant="h5" component="h2">
          Drag and drop your file here or
        </Typography>
        <input
          accept=".xlsx,.xls"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
          onClick={handleImport}
          style={{ marginBlock: 10 }}
        >
          Upload File
        </Button>
        {file && (
          <Typography variant="body1" component="p">
            Uploaded File: {file.name}
          </Typography>
        )}
        {file && (
          <IconButton aria-label="delete" onClick={clearUploadedFile}>
            <DeleteOutline />
          </IconButton>
        )}
      </Paper>
      </>}
    </div>
  );
};

export default withRouter(LoanCsvImport);
