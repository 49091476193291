import React, { Component } from "react";
import "../header/header.scss";
import { HashRouter as Router, Route, Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import companylogo from "../../assets/images/companylogo.svg";
import Signup from "../signup/signup";
import Login from "../login/login";
import start from "../GetStarted/start";
import About from "../Aboutus/aboutus";
import Contact from "../Contactus/contact";
import GoogleSignin from "../login/GoogleSignin/GoogleSignin";
import OtpSignin from "../login/OtpSignin/OtpSignin";
import SignUp1 from "../login/Signup/Signup";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeColorsignup: "",
      changeColorlogin: "",
      changeColorabout: "",
      changeColorcont: "",
      signupbackground: "transparent !important",
      loginbackground: "transparent !important",
      aboutbackground: "transparent !important",
      contbackground: "transparent !important",
      Textcolorlogin: "#3E4664!important",
      Textcolorsignup: "#3E4664!important",
      Textcolorabout: "#3E4664!important",
      Textcolorcont: "#3E4664!important",
    };
  }

  componentDidMount() {
    if (
      window.location.href.split("#")[1] === "/signup" ||
      window.location.href.split("#")[1] === "/signup/register"
    ) {
      this.setState({
        changeColorsignup: "1px solid #8E8E98",
        Textcolorsignup: "#00D95E",
        signupbackground: "transparent",
        changeColorlogin: "",
        Textcolorlogin: "#3E4664",
        loginbackground: "transparent",
        changeColorabout: "",
        Textcolorabout: "#3E4664",
        aboutbackground: "transparent",
        changeColorcont: "",
        Textcolorcont: "#3E4664",
        contbackground: "transparent",
      });
    } else if (window.location.href.split("#")[1] === "/googlesignin") {
        this.setState({
          changeColorsignup: "",
          Textcolorsignup: "#3E4664",
          signupbackground: "transparent",
          changeColorlogin: "",
          Textcolorlogin: "#3E4664",
          loginbackground: "transparent",
          changeColorabout: "1px solid #8E8E98",
          Textcolorabout: "#00D95E",
          aboutbackground: "transparent",
          changeColorcont: "",
          Textcolorcont: "#3E4664",
          contbackground: "transparent",
        });
    } else if (window.location.href.split("#")[1] === "/otpsignin") {
        this.setState({
          changeColorsignup: "",
          Textcolorsignup: "#3E4664",
          signupbackground: "transparent",
          changeColorlogin: "",
          Textcolorlogin: "#3E4664",
          loginbackground: "transparent",
          changeColorabout: "1px solid #8E8E98",
          Textcolorabout: "#00D95E",
          aboutbackground: "transparent",
          changeColorcont: "",
          Textcolorcont: "#3E4664",
          contbackground: "transparent",
        });
    } else if (window.location.href.split("#")[1] === "/aboutus") {
      this.setState({
        changeColorsignup: "",
        Textcolorsignup: "#3E4664",
        signupbackground: "transparent",
        changeColorlogin: "",
        Textcolorlogin: "#3E4664",
        loginbackground: "transparent",
        changeColorabout: "1px solid #8E8E98",
        Textcolorabout: "#00D95E",
        aboutbackground: "transparent",
        changeColorcont: "",
        Textcolorcont: "#3E4664",
        contbackground: "transparent",
      });
    } else if (window.location.href.split("#")[1] === "/contact") {
      this.setState({
        changeColorsignup: "",
        Textcolorsignup: "#3E4664",
        signupbackground: "transparent",
        changeColorlogin: "",
        Textcolorlogin: "#3E4664",
        loginbackground: "transparent",
        changeColorabout: "",
        Textcolorabout: "#3E4664",
        aboutbackground: "transparent",
        changeColorcont: "1px solid #8E8E98",
        Textcolorcont: "#00D95E",
        contbackground: "transparent",
      });
    } else {
      this.setState({
        changeColorlogin: "1px solid #8E8E98",
        Textcolorlogin: "#00D95E",
        loginbackground: "transparent",
        changeColorsignup: "",
        Textcolorsignup: "#3E4664",
        signupbackground: "transparent",
        changeColorabout: "",
        Textcolorabout: "#3E4664",
        aboutbackground: "transparent",
        changeColorcont: "",
        Textcolorcont: "#3E4664",
        contbackground: "transparent",
      });
    }
  }
  componentWillReceiveProps = () => {
    if (window.location.href.split("#")[1] === "/login") {
      this.setState({
        changeColorlogin: "1px solid #8E8E98",
        Textcolorlogin: "#00D95E",
        loginbackground: "transparent",
        changeColorsignup: "",
        Textcolorsignup: "#3E4664",
        signupbackground: "transparent",
      });
    }
  };

  login() {
    this.setState({
      changeColorlogin: "1px solid #8E8E98",
      Textcolorlogin: "#00D95E",
      loginbackground: "transparent",
      changeColorsignup: "",
      Textcolorsignup: "#3E4664",
      signupbackground: "transparent",
      changeColorabout: "",
      Textcolorabout: "#3E4664",
      aboutbackground: "transparent",
      changeColorcont: "",
      Textcolorcont: "#3E4664",
      contbackground: "transparent",
    });
    this.props.history.push("/login");
  }
  signup() {
    this.setState({
      changeColorsignup: "1px solid #8E8E98",
      Textcolorsignup: "#00D95E",
      signupbackground: "transparent",
      changeColorlogin: "",
      Textcolorlogin: "#3E4664",
      loginbackground: "transparent",
      changeColorabout: "",
      Textcolorabout: "#3E4664",
      aboutbackground: "transparent",
      changeColorcont: "",
      Textcolorcont: "#3E4664",
      contbackground: "transparent",
    });
    this.props.history.push("/signup");
  }
  about() {
    this.setState({
      changeColorsignup: "",
      Textcolorsignup: "#3E4664",
      signupbackground: "transparent",
      changeColorlogin: "",
      Textcolorlogin: "#3E4664",
      loginbackground: "transparent",
      changeColorabout: "1px solid #8E8E98",
      Textcolorabout: "#00D95E",
      aboutbackground: "transparent",
      changeColorcont: "",
      Textcolorcont: "#3E4664",
      contbackground: "transparent",
    });
    this.props.history.push("/aboutus");
  }
  contact() {
    this.setState({
      changeColorsignup: "",
      Textcolorsignup: "#3E4664",
      signupbackground: "transparent",
      changeColorlogin: "",
      Textcolorlogin: "#3E4664",
      loginbackground: "transparent",
      changeColorabout: "",
      Textcolorabout: "#3E4664",
      aboutbackground: "transparent",
      changeColorcont: "1px solid #8E8E98",
      Textcolorcont: "#00D95E",
      contbackground: "transparent",
    });
    this.props.history.push("/contact");
  }
  render() {
    return (
      <section className="headerroot">
        <AppBar position="static" className="bar">
          <Toolbar className="headerDiv">
            {/*  <IconButton edge="start" color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton> */}
            <Typography variant="h6" className="title">
              <img src={companylogo} alt="logo" className="image" />
            </Typography>
            {/* <div className="bttn"> */}
              {/* <Link to="/signup" style={{ textDecoration: 'none', color: '#3E4664' }}> */}
              {/* <div>
                            <Button className="Fonts" style={{textTransform:'capitalize',border: this.state.changeColorabout, color:this.state.Textcolorabout, backgroundColor:this.state.aboutbackground,fontWeight: "600"}} onClick={this.about.bind(this)}>ABOUT US</Button>
                            
                            </div> */}
              {/* <div>
                            <Button className="Fonts" style={{textTransform:'capitalize',border: this.state.changeColorcont, color:this.state.Textcolorcont, backgroundColor:this.state.contbackground,fontWeight: "600"}} onClick={this.contact.bind(this)}>CONTACT US</Button>
                            
                            </div> */}
              {/* <div>
                <Button
                  className="Fonts"
                  style={{
                    textTransform: "capitalize",
                    border: this.state.changeColorsignup,
                    color: this.state.Textcolorsignup,
                    backgroundColor: this.state.signupbackground,
                    fontWeight: "600",
                  }}
                  onClick={this.signup.bind(this)}
                >
                  SIGN UP
                </Button> */}
                {/* </Link> */}
              {/* </div> */}

              {/* <Link to="/" style={{ textDecoration: 'none', color: '#3E4664' }}> */}
              {/* <div> */}
                {/* <Button
                  className="Fonts"
                  style={{
                    textTransform: "capitalize",
                    border: this.state.changeColorlogin,
                    color: this.state.Textcolorlogin,
                    backgroundColor: this.state.loginbackground,
                    fontWeight: "600",
                  }}
                  onClick={this.login.bind(this)}
                >
                  LOG IN
                </Button> */}
                {/* </Link>   */}
              {/* </div>
            </div> */}
          </Toolbar>
        </AppBar>
        <div>
          <Route exact path="/login" component={Login} />
          <Route exact path="/otpsignin" component={OtpSignin} />
          <Route exact path="/googlesignin" component={GoogleSignin} />
          <Route exact path="/signup" component={start} />
          <Route exact path="/signup/register" component={SignUp1} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/contact" component={Contact} />
        </div>
      </section>
    );
  }
}
export default Header;
